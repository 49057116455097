import {organizationUuidState} from "../store";
import fetcher from "./fetcher";
import {store} from "../index";

export const saveTeam = (name: string) => {
    const organizationUuid = store.get(organizationUuidState)
    return fetcher('POST', `/team`, organizationUuid, {name});
}

export const deleteTeams = (teamUuids: string[]) => {
    if (teamUuids?.length <= 0) {
        return Promise.resolve();
    }
    const organizationUuid = store.get(organizationUuidState)
    return fetcher('DELETE', `/teams`, organizationUuid, teamUuids);
}

export const saveTeams = (teamNames: string[]) => {
    if (teamNames?.length <= 0) {
        return Promise.resolve();
    }
    const organizationUuid = store.get(organizationUuidState)
    return fetcher('POST', `/teams`, organizationUuid, teamNames.map(name => ({name})));

}

export const getTeams = () => {
    const organizationUuid = store.get(organizationUuidState)
    return fetcher('GET', `/teams`, organizationUuid);
}

