import {ToggleButton} from "react-bootstrap";
import React from "react";

import ItemAddModal from "./ItemAddModal";
import {wizardProcessState} from "../../store";
import {useAlerts} from "../../hooks/useAlerts";
import {saveTemplateTask} from "../../api/templates";
import {saveTool} from "../../api/tools";
import {saveTeam} from "../../api/teams";
import {useAtom} from "jotai/react";
import {HoverTooltip} from "../../components/HoverTooltip";

interface ProcessItemSelectProps {
    itemType: 'tasks' | 'teams' | 'tools',
    hideAdd?: boolean
}

export default function WizardItemSelect(props: ProcessItemSelectProps) {

    const [wizardProcess, setWizardProcess] = useAtom(wizardProcessState)
    const {addError} = useAlerts();

    const handleSelect = (name: string) => {
        setWizardProcess(prev => ({
            ...prev,
            [props.itemType]: [...prev[props.itemType].map(item => item.name === name ? {
                ...item,
                selected: !item.selected
            } : item)]
        }))
    }

    const saveNewItem = async (name: string) => {
        if (props.itemType === 'tasks') {
            return saveTemplateTask({name, templateUuid: wizardProcess.templateUuid})
        } else if (props.itemType === 'tools') {
            return saveTool(name)
        } else if (props.itemType === 'teams') {
            return saveTeam(name)
        }
    }

    const handleNewItem = (name: string) => {
        saveNewItem(name)
            .then(data => data.uuid)
            .then(uuid => setWizardProcess(prev => ({
                ...prev,
                [props.itemType]: [...prev[props.itemType].filter(item => item.name !== name), {
                    name,
                    selected: true,
                    uuid
                }]
            })))
            .catch(error => addError(error))
    }

    return (
        <div className="d-flex flex-wrap justify-content-start gap-2 animated-resize">
            {wizardProcess[props.itemType].filter(item => item.name !== 'None').map((item, index) => (
                    <ToggleButton
                        style={{maxWidth: "200px"}}
                        className="text-nowrap truncate"
                        size="sm"
                        key={`${props.itemType}_${index}`}
                        id={`${props.itemType}_${index}`}
                        type="checkbox"
                        variant="outline-primary"
                        checked={item.selected}
                        onChange={() => handleSelect(item.name)}
                        value={item.name}>
                        <HoverTooltip delay={1200} text={item.name}>

                        {item.name}
                        </HoverTooltip>

                        </ToggleButton>
            ))}
            {!props.hideAdd &&
                <ItemAddModal
                    size="24px"
                    handleNewItem={handleNewItem}
                    itemType={props.itemType}/>
            }
        </div>
    )
}