import {FullContainer} from "../../components/containers/FullContainer";
import React, {useEffect, useMemo, useState} from "react";
import {Title} from "../../components/Title";
import {Col, Row, Table} from "react-bootstrap";
import {NamedCard} from "../home-view/NamedCard";
import DatePicker from "react-datepicker";
import {Line, LineChart, ResponsiveContainer, XAxis, YAxis} from "recharts";
import {ArrowLink} from "../../components/ArrowLink";
import {Process} from "../../interfaces";
import {getProcessList} from "../../api/process";
import {useAlerts} from "../../hooks/useAlerts";
import {ChevronDoubleDownIcon, ChevronUpIcon} from "@heroicons/react/24/solid";
import createTrend from "trendline";

export default function AnalyticsReportView() {

    const [startDate, setStartDate] = useState<Date | null>()
    const [endDate, setEndDate] = useState<Date | null>()
    const [data, setData] = useState<any[]>([])
    const [processes, setProcesses] = useState<Process[]>([])
    const {addError} = useAlerts();

    const generateMonthlyDates = (start: Date, end: Date) => {
        return Array.from({length: (end.getFullYear() - start.getFullYear()) * 12 + (end.getMonth() - start.getMonth()) + 1}, (_, i) => {
            const date = new Date(start);
            date.setMonth(start.getMonth() + i);
            return date;
        });
    }

    function getRandomNumber(max: number) {
        return Math.floor(Math.random() * (max));
    }

    useEffect(() => {
        const start = new Date()
        setStartDate(new Date(new Date().setMonth(new Date().getMonth() - 3)))
        start.setFullYear(new Date().getFullYear() - 1)
        const end = new Date();
        setEndDate(end)
        const dates = generateMonthlyDates(start, end)
        const generatedData = dates.map((date: Date, index) => {
            return {
                date,
                name: date.toISOString().substring(0, 7),
                loss: getRandomNumber(10000),
                index
            };
        });
        setData(generatedData);
    }, []);

    useEffect(() => {
        getProcessList()
            .then(data => setProcesses(data))
            .catch(addError)
    }, []);

    const filteredData = useMemo(() => data.filter((d) => d.date >= startDate! && d.date <= endDate!), [data, endDate, startDate]);

    const mergedData = useMemo(() => {
        if (filteredData?.length) {
            const trend = createTrend(filteredData, "index", "loss");

            return filteredData.map((data, index) =>
                ({
                    ...data,
                    trendLoss: (index === 0 || index === filteredData.length - 1) ? trend.calcY(data.index) : undefined
                })
            )
        }
        return []
    }, [filteredData]);

    return (
        <FullContainer>
            <Title title={"Analytics (DEMO)"} className="mb-3"></Title>

            <Row className="mt-2 mb-3">
                <Col>
                    <NamedCard name={"Revenue Leakage"}>
                        <Row>
                            <Col md={6}></Col>
                            <Col><strong>Start</strong> <DatePicker
                                showMonthYearPicker={true}
                                dateFormat="MM/yyyy"
                                className="form-control"
                                selected={startDate} onChange={setStartDate}/></Col>
                            <Col><strong>End</strong> <DatePicker
                                showMonthYearPicker={true}
                                dateFormat="MM/yyyy"
                                className="form-control"
                                onChange={setEndDate} selected={endDate}/></Col>
                        </Row>
                        <Row className="mt-3">
                            <Col>
                                <div style={{width: '100%', height: '200px'}}>
                                    <ResponsiveContainer>
                                        <LineChart data={mergedData}>
                                            <XAxis dataKey="name"/>
                                            <YAxis unit=" €"/>
                                            <Line dataKey="loss" fill="#FBD1A2" strokeWidth="3"/>
                                            <Line connectNulls={true} dataKey="trendLoss" stroke="red"
                                                  strokeDasharray="3 3"/>
                                        </LineChart>
                                    </ResponsiveContainer>
                                </div>
                            </Col>
                        </Row>
                    </NamedCard>
                </Col>
            </Row>

            <Row>
                <Col>
                    <NamedCard name="Stats">
                        <Table>
                            <thead>
                            <tr>
                                <th>Process</th>
                                <th>Cycle Time</th>
                                <th>On-Time Delivery Rate</th>
                                <th>Revenue Leakage</th>
                            </tr>
                            </thead>
                            <tbody>
                            {processes.map(process => <tr key={process.uuid}>
                                <td><ArrowLink name={process.name} to={`/analytics/process/${process.uuid}`}/></td>
                                <td>{`${40 - getRandomNumber(20)} days`} {Math.random() < 0.5 ?
                                    <ChevronUpIcon className="icon-style-sm"/> :
                                    <ChevronDoubleDownIcon className="icon-style-sm"/>}</td>
                                <td>{`${getRandomNumber(100)}%`} {Math.random() < 0.5 ?
                                    <ChevronUpIcon className="icon-style-sm"/> :
                                    <ChevronDoubleDownIcon className="icon-style-sm"/>}</td>
                                <td>€ {getRandomNumber(10000)} {Math.random() < 0.5 ?
                                    <ChevronUpIcon className="icon-style-sm"/> :
                                    <ChevronDoubleDownIcon className="icon-style-sm"/>}</td>
                            </tr>)}
                            </tbody>
                        </Table>
                    </NamedCard>
                </Col>
            </Row>
        </FullContainer>
    )
}