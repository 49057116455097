import React, {ChangeEvent, forwardRef, useEffect, useImperativeHandle, useState} from 'react';
import {IDoesFilterPassParams} from 'ag-grid-community';

const SetFilter = forwardRef((props: any, ref) => {
    const [uniqueValues, setUniqueValues] = useState<string[]>([]);
    const [selectedValue, setSelectedValue] = useState<string>(props.model ? props.model.value : '');

    useEffect(() => {
        const values = getUniqueValues(props);
        setUniqueValues(values);
    }, [props]);

    const getUniqueValues = (props: any): string[] => {
        return props.filterParams?.filterValues || [];
    };

    const onChange = (event: ChangeEvent<HTMLSelectElement>): void => {
        const value = event.target.value;
        setSelectedValue(value);
        props.onModelChange(value ? { value } : null);
    };

    useImperativeHandle(ref, () => ({
        doesFilterPass(params: IDoesFilterPassParams): boolean {
            return selectedValue === '' || params.data[props.colDef.field as string] === selectedValue;
        }
    }));

    return (
        <div className="ag-input-wrapper ag-text-field-input-wrapper">
            <select className="ag-select" onChange={onChange} value={selectedValue}>
                <option value=''>All</option>
                {uniqueValues.map((value) => (
                    <option key={value} value={value}>
                        {props.filterParams?.filterLabels[value] || value}
                    </option>
                ))}
            </select>
        </div>
    );
});

export default SetFilter;