import {Invitation, OrganizationMember, PendingInvitation, Role, Team} from "../../../interfaces";
import React, {useCallback, useEffect, useMemo, useState} from "react";
import {userState} from "../../../store";
import {Section} from "../../../components/Section";
import {useAlerts} from "../../../hooks/useAlerts";
import {getOrganizationInvitations} from "../../../api/organizations";
import {cancelInvitation, createInvitation, deleteInvitation} from "../../../api/invitations";
import {useAtomValue} from "jotai/react";
import {mapEditableCell} from "../../../components/data-table/util";
import {INVITATION_STATUSES, ROLES} from "../../../utils/Constants";
import {AgTable} from "../../../components/AgTable";
import {Button} from "react-bootstrap";
import {useToast} from "../../../components/toasts/UseToast";
import {EditableCell} from "../../../components/data-table/EditableCell";
import emailRegex from "email-regex";
import {ExclamationCircleIcon} from "@heroicons/react/24/outline";

interface InvitedUsersParams {
    organizationUuid?: string,
    isOwner: boolean,
    members: OrganizationMember[],
    teams: Team[]
}

interface UnsentInviteRow {
    uuid?: string;
    email?: string;
    team: string;
    role: Role;
}

export const InvitedUsersList = ({organizationUuid, isOwner, members, teams}: InvitedUsersParams) => {
    const user = useAtomValue(userState)
    const [pendingInvites, setPendingInvites] = useState<PendingInvitation[]>([])
    const [unsentInvites, setUnsentInvites] = useState<UnsentInviteRow[]>([]);

    const {addError} = useAlerts()
    const [setToast] = useToast()

    useEffect(() => {
        getOrganizationInvitations()
            .then(data => setPendingInvites(data))
            .catch(error => addError(error))
    }, [addError, organizationUuid]);

    const forbiddenEmails = useMemo(() => [
        ...pendingInvites.map(existingInvite => existingInvite.email),
        ...members.map(member => member.email)
    ], [pendingInvites, members])

    const handleCancelInvitation = useCallback((invitation: PendingInvitation) => {
        cancelInvitation(invitation.uuid!)
            .then(_ => getOrganizationInvitations())
            .then(data => setPendingInvites(data))
            .catch(error => addError(error))
    }, [addError])

    const handleDeleteInvitation = useCallback((invitation: PendingInvitation) => {
        deleteInvitation(invitation.uuid!)
            .then(_ => getOrganizationInvitations())
            .then(data => setPendingInvites(data))
            .catch(error => addError(error))
    }, [addError])

    const handleCreateInvitation = useCallback((invitation: Invitation) => {
        createInvitation(invitation)
            .then(_ => getOrganizationInvitations())
            .then(data => setPendingInvites(data))
            .then(_ => setUnsentInvites(prev => prev.filter(prevInvite => prevInvite.email !== invitation.email)))
            .catch(error => addError(error))
    }, [addError])

    const handleAddInvite = () => {
        setUnsentInvites([...unsentInvites, {
            uuid: crypto.randomUUID(),
            email: undefined,
            team: teams.at(0)?.uuid!,
            role: 'MEMBER'
        }]);
    };

    const canRevoke = (invite: PendingInvitation) => {
        return isOwner || [invite.email, invite.invitedByEmail].includes(user?.email)
    }

    const computeValidity = useCallback((email: string | undefined) => {
        if (!email) {
            return "Email required"
        } else if (forbiddenEmails.includes(email)) {
            return "Duplicate email"
        } else if (!emailRegex({exact: true}).test(email)) {
            return "Invalid email"
        } else {
            return '';
        }
    }, [forbiddenEmails]);

    const rows = useMemo(() => {
        const existingInviteRows = pendingInvites.map(invite => ({
            email: invite.email!,
            team: invite.team.uuid,
            role: invite.role as Role,
            status: invite.status,
            invite,
            addRow: false
        }));
        const newInvitesRows = unsentInvites.map(invite => ({
            email: invite.email!,
            team: invite.team!,
            role: invite.role as Role,
            status: 'UNSENT',
            invite,
            addRow: false,
            validity: computeValidity(invite.email)
        }));
        return [
            ...existingInviteRows,
            ...newInvitesRows];
    }, [computeValidity, pendingInvites, unsentInvites]);

    const teamLabels = useMemo(() => Object.fromEntries(teams.map(team => [team.uuid, team.name])), [teams])
    const teamValues = useMemo(() => teams.map(team => team.uuid), [teams])

    const handleCopy = useCallback((invitation: PendingInvitation) => {
        navigator.clipboard
            .writeText(invitation.link)
            .catch(addError)
    }, [addError])

    const onCellEditRequest = (params: any) => {
        const changedRow = params.data;
        setUnsentInvites((prevData) =>
            prevData.map((row) => row.uuid === changedRow.invite.uuid ?
                {...row, email: changedRow.email, team: changedRow.team, role: changedRow.role} : row)
        );
    }

    return (
        <Section title="Invitations">
            <div style={{width: '1040px'}} className="pinned-row">
                <AgTable
                    onCellValueChanged={onCellEditRequest}
                    singleClickEdit={true}
                    stopEditingWhenCellsLoseFocus={true}
                    onCellEditRequest={params => onCellEditRequest(params.data)}
                    pinnedBottomRowData={[{
                        email: undefined,
                        team: '',
                        role: 'MEMBER' as Role,
                        status: 'ADD_ROW',
                        addRow: true,
                    }]}
                    columnDefs={[
                        {
                            field: "email",
                            width: 250,
                            colSpan: (params: any) => params.data.addRow ? 5 : 1,
                            cellRenderer: (params: any) => params.data.addRow ?
                                <div className="d-flex justify-content-center align-items-center h-100">
                                    <Button size="sm" onClick={() => handleAddInvite()}>Add New</Button></div> :
                                <EditableCell disable={params.data.status !== 'UNSENT'} value={params.value}/>,
                            editable: (params: any) => params.data.status === 'UNSENT',
                        },
                        mapEditableCell({
                            field: "team",
                            width: 200,
                            values: teamValues,
                            labels: teamLabels,
                            editable: (params: any) => params.data.status === 'UNSENT'
                        }),
                        mapEditableCell({
                            field: "role",
                            width: 100,
                            values: Object.keys(ROLES),
                            labels: ROLES,
                            editable: (params: any) => params.data.status === 'UNSENT'
                        }),
                        {
                            field: "status",
                            width: 200,
                            valueFormatter: (params: any) => INVITATION_STATUSES[params.value],
                        },
                        {
                            headerName: 'Actions',
                            cellRenderer: (params: any) => {
                                const invite = params.data.invite;
                                const status = params.data.status;
                                return (<div className="d-flex flex-row gap-2 align-items-center h-100 row-hover-cell">
                                    {status === 'PENDING' &&
                                        <Button size="sm" onClick={() => {
                                            handleCopy(invite);
                                            setToast("Link copied to clipboard")
                                        }}>Link</Button>
                                    }
                                    {canRevoke(invite) && invite?.status === 'PENDING' &&
                                        <Button size="sm" variant="danger" onClick={() => {
                                            handleCancelInvitation(invite)
                                        }}>Revoke</Button>
                                    }
                                    {canRevoke(invite)
                                        && status !== 'PENDING'
                                        && status !== 'UNSENT'
                                        && <Button size="sm" variant="danger" onClick={() => {
                                            handleDeleteInvitation(invite)
                                        }}>Delete</Button>
                                    }
                                    {status === 'UNSENT' && !params.data.validity &&
                                        <Button size="sm"
                                                variant="primary"
                                                disabled={params.data.validity}
                                                onClick={() => {
                                                    handleCreateInvitation(invite)
                                                }}>Send</Button>
                                    }
                                    {status === 'UNSENT' && params.data.validity &&
                                        <div className="d-flex align-items-center gap-1 h-100"><ExclamationCircleIcon
                                            className="icon-style-sm"/>{params.data.validity}</div>
                                    }
                                </div>);
                            },
                            width: 200,
                        }]}
                    rowData={rows}
                    domLayout='autoHeight'
                    rowClass="row-hover"
                    suppressCellFocus={true}
                />
            </div>
        </Section>)
}