import {organizationUuidState} from "../store";
import fetcher from "./fetcher";
import {store} from "../index";

export const saveTool = (name: string) => {
    const organizationUuid = store.get(organizationUuidState)
    return fetcher('POST', `/tool`, organizationUuid, {name});
}

export const deleteTools = (toolUuids: string[]) => {
    if (toolUuids?.length <= 0) {
        return Promise.resolve();
    }
    const organizationUuid = store.get(organizationUuidState)
    return fetcher('DELETE', `/tools`, organizationUuid, toolUuids);
}

export const saveTools = (toolNames: string[]) => {
    if (toolNames?.length <= 0) {
        return Promise.resolve();
    }
    const organizationUuid = store.get(organizationUuidState)
    return fetcher('POST', `/tools`, organizationUuid, toolNames.map(name => ({name})));

}

export const getTools = () => {
    const organizationUuid = store.get(organizationUuidState)
    return fetcher('GET', `/tools`, organizationUuid);
}


