import {atom} from "jotai";
import {
    AlertMessage,
    ErrorState,
    Process,
    User,
    UserOrganization,
    WizardProcess,
    WizardTask,
    WizardTool
} from "./interfaces";
import {atomWithReset} from "jotai/vanilla/utils";
import {atomWithStorage} from "jotai/utils";

export const errorState = atom<ErrorState>({});

export const userState = atom<User | null>({organizations: [], name: '', email: '', picture: '', resetPossible: false});

export const organizationUuidState = atomWithStorage<string | null>('organizationUuidState', '', undefined, {getOnInit: true})

export const organizationState = atom<UserOrganization | null>((get) => {
    const user = get(userState);
    const organizationUuid = get(organizationUuidState);
    return user?.organizations.find(organization => organization.uuid === organizationUuid) || null;
});

export const wizardProcessState = atomWithReset<WizardProcess>({
        name: '',
        code: '',
        uuid: '',
        status: 'PENDING',
        createdBy: '',
        createdAt: '',
        templateUuid: '',
        tasks: [],
        tools: [],
        teams: [],
    }
)

export const wizardArrangeTasksState = atom(
    (get: any) => {
        const wizardProcess = get(wizardProcessState);
        return wizardProcess.tasks?.filter((task: WizardTool) => task.selected) || []
    },
    (get, set, updateFn: (prev: WizardTask[]) => WizardTask[]) => {
        const wizardProcess = get(wizardProcessState);
        const unselected = wizardProcess.tasks.filter(task => !task.selected);
        const newValues = updateFn(get(wizardArrangeTasksState))
        const newState = {
            ...wizardProcess,
            tasks: [...newValues, ...unselected]
        };
        set(wizardProcessState, newState);
    },
);

export const processTopologicalOrderState = atom<string[]>([])

export const processViewEditModeState = atom<boolean>(false)

export const processActiveTaskUuid = atomWithReset<string | null>(null)

export const resettingGraphState = atom<boolean>(false)

export const alertsState = atom<AlertMessage[]>([])

export const toastShowState = atom<boolean>(false)
export const toastMessageState = atom<string>('')