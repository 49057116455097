import {MouseSensor as LibMouseSensor} from '@dnd-kit/core';
import {MouseEvent} from 'react';

const handler = ({nativeEvent: event}: MouseEvent) => {
    let cur = event.target as HTMLElement;

    while (cur) {
        if (cur.dataset && cur.dataset.noDnd) {
            return false;
        }
        cur = cur.parentElement as HTMLElement;
    }

    return true;
};

export class MouseSensor extends LibMouseSensor {
    static activators = [{eventName: 'onMouseDown', handler}] as typeof LibMouseSensor['activators'];
}