export const ROLES: { [key: string]: string } = {
    OWNER: "Admin",
    MEMBER: "User",
};

export const INVITATION_STATUSES = {
    EXPIRED: "Expired",
    PENDING: "Pending",
    CANCELLED: "Revoked",
    COMPLETE: "Accepted",
    DECLINED: "Declined",
};

export const TASK_STATUS = {
    NOT_STARTED: "Waiting",
    ON_TRACK: "On track",
    DELAYED: "Delayed",
    SKIPPED: "Skipped",
    CANCELLED: "Cancelled",
    COMPLETED: "Completed",
    ON_HOLD: "On hold",
};

export const PROCESS_STATUS = {
    PENDING: "Draft",
    COMPLETE: "Complete",
    DELETED: "Deleted",
};

export const PROJECT_STATUS = {
    NOT_STARTED: "Waiting",
    ON_TRACK: "On track",
    DELAYED: "Delayed",
    ON_HOLD: "On hold",
    COMPLETED: "Completed",
    CANCELLED: "Cancelled",
};

export const PLAN_NAMES = {
    pilot_monthly: "Pilot",
    growth_monthly: "Growth",
    scale_monthly: "Scale",
};

export const BILLING_STATUS = {
    NONE: "None",
    ACTIVE: "Active",
    DELAYED: "Delayed",
    PENDING: "Pending",
    INACTIVE: "Inactive",
    EXPIRED: "Expired",
};

export const PROJECT_STATUS_OPTIONS = Object.entries(PROJECT_STATUS).map(([key, value]) => ({
    label: value,
    value: key,
}));

export const TEAM_OPTIONS = [
    {label: "Engineering", value: "Engineering"},
    {label: "Design", value: "Design"},
    {label: "Sales", value: "Sales"},
    {label: "Business development", value: "Business development"},
    {label: "Finance", value: "Finance"},
    {label: "Operations", value: "Operations"},
    {label: "Product", value: "Product"},
    {label: "Marketing", value: "Marketing"},
    {label: "Legal", value: "Legal"},
    {label: "Compliance", value: "Compliance"},
    {label: "Customer success", value: "Customer success"},
    {label: "Customer support", value: "Customer support"},
    {label: "Integrations support", value: "Integrations support"},
];

export const TOOL_OPTIONS = [
    {label: "Internal Backoffice", value: "Internal Backoffice"},
    {label: "Slack", value: "Slack"},
    {label: "Microsoft Teams", value: "Microsoft Teams"},
    {label: "Zoom", value: "Zoom"},
    {label: "Google Workspace", value: "Google Workspace"},
    {label: "Microsoft 365", value: "Microsoft 365"},
    {label: "Asana", value: "Asana"},
    {label: "Trello", value: "Trello"},
    {label: "Jira", value: "Jira"},
    {label: "Confluence", value: "Confluence"},
    {label: "Basecamp", value: "Basecamp"},
    {label: "Lucidchart", value: "Lucidchart"},
    {label: "Miro", value: "Miro"},
    {label: "Mural", value: "Mural"},
    {label: "Loom", value: "Loom"},
    {label: "Notion", value: "Notion"},
    {label: "Salesforce", value: "Salesforce"},
    {label: "HubSpot", value: "HubSpot"},
    {label: "Pipedrive", value: "Pipedrive"},
    {label: "Intercom", value: "Intercom"},
    {label: "Freshdesk", value: "Freshdesk"},
    {label: "Zendesk", value: "Zendesk"},
    {label: "Figma", value: "Figma"},
    {label: "SharePoint", value: "SharePoint"},
    {label: "BambooHR", value: "BambooHR"},
    {label: "Gusto", value: "Gusto"},
    {label: "QuickBooks Online", value: "QuickBooks Online"},
    {label: "Xero", value: "Xero"},
    {label: "Dropbox", value: "Dropbox"},
    {label: "Box", value: "Box"},
    {label: "DocuSign", value: "DocuSign"},
    {label: "Power BI", value: "Power BI"},
    {label: "Tableau", value: "Tableau"},
    {label: "Canva", value: "Canva"}
];

export const ROLE_OPTIONS = [
    {value: 'MEMBER', label: 'User'},
    {value: 'OWNER', label: 'Admin'}
];
