import React, {useEffect, useState} from "react";
import {Process, WizardProcess} from "../../interfaces";
import {useNavigate} from "react-router";
import {organizationUuidState, wizardProcessState} from "../../store";
import {ProcessListRow} from "./ProcessListRow";
import {Title} from "../../components/Title";
import {Section} from "../../components/Section";
import {Table} from "react-bootstrap";
import {FullContainer} from "../../components/containers/FullContainer";
import {useAlerts} from "../../hooks/useAlerts";
import {deleteWizardProcess, getWizardProcessList} from "../../api/wizard";
import {deleteProcess, getProcessList} from "../../api/process";
import {useConfirmModal} from "../../components/modals/UseConfirmModal";
import {useResetAtom} from "jotai/react/utils";
import {useAtom} from "jotai/react";

export default function ProcessListView() {

    const [wizardProcessList, setWizardProcessList] = useState<WizardProcess[]>([])
    const [processList, setProcessList] = useState<Process[]>([])
    const organizationUuid = useAtom(organizationUuidState)[0]
    const navigate = useNavigate();
    const resetProcess = useResetAtom(wizardProcessState)
    const {addError} = useAlerts();
    const {showModal} = useConfirmModal()

    useEffect(() => {
        getWizardProcessList()
            .then(result => setWizardProcessList(result || []))
            .catch(error => addError(error))
    }, [addError, organizationUuid]);

    useEffect(() => {
        getProcessList()
            .then(result => setProcessList(result || []))
            .catch(error => addError(error))
    }, [addError, organizationUuid]);


    const handleWizardSelect = (process: WizardProcess) => {
        navigate(`/wizard/tasks/${process.uuid}`)
    }

    const handleSelect = (process: Process) => {
        navigate(`/process/${process.uuid}`);
    }

    const handleWizardDelete = (process: WizardProcess) => {
        const deleteAction = () => deleteWizardProcess(process.uuid)
            .then(_ => getWizardProcessList())
            .then(result => setWizardProcessList(result || []))
            .catch(error => addError(error));
        showModal(`Are you sure you want to delete ${process.name}?`, deleteAction);
    }

    const handleDelete = (process: Process) => {
        const deleteAction = () => deleteProcess(process.uuid)
            .then(_ => getProcessList())
            .then(result => setProcessList(result || []))
            .catch(error => addError(error));
        showModal(`Are you sure you want to delete ${process.name}?`, deleteAction);
    }

    return (<>
        <FullContainer>
            <Title title="Processes" className="mb-3"/>
            <Section>
                <Table>
                    <thead>
                    <tr>
                        <th>Name</th>
                        <th>Code</th>
                        <th>Updated by</th>
                        <th>Updated at</th>
                        <th>Status</th>
                        <th>Actions</th>
                    </tr>
                    </thead>
                    <tbody>
                    {processList?.map(process =>
                        <ProcessListRow key={process.uuid}
                                        process={process}
                                        onDelete={handleDelete}
                                        onSelect={handleSelect}/>
                    )}
                    {wizardProcessList?.map(process =>
                        <ProcessListRow key={process.uuid}
                                        process={process}
                                        onDelete={handleWizardDelete}
                                        onSelect={handleWizardSelect}/>
                    )}
                    <tr>
                        <td colSpan={6} onClick={() => {
                            resetProcess();
                            navigate('/wizard/tasks');
                        }} className="pointer text-center text-muted">Add a new process
                        </td>
                    </tr>
                    </tbody>
                </Table>

            </Section>
        </FullContainer>
    </>)
}

