import React from "react";
import {Button} from "react-bootstrap";
import {FullContainer} from "../../components/containers/FullContainer";
import {HoverTooltip} from "../../components/HoverTooltip";
import {NamedCard} from "../home-view/NamedCard";
import {Title} from "../../components/Title";
import WizardProgress from "./WizardProgress";

export interface WizardFrameProps {
    title: any;
    children: any,
    next?: () => void,
    prev?: () => void,
    nextDisabled?: boolean,
    nextLabel?: string,
    prevLabel?: string,
    nextDisabledTooltip?: string
}

export default function WizardFrame(props: WizardFrameProps) {

    return (<>
        <FullContainer>
            <Title title={props.title} className="me-auto mb-3"/>
            <NamedCard className="w-75 bg-body-tertiary border-0 p-2">
                <div className="d-flex flex-row">
                    <div className="ms-auto mb-2">
                        <WizardProgress/>
                    </div>
                </div>
                {props.children}
                <div className="d-flex flex-row">
                    <div className="d-flex flex-row mt-3 mb-1 ms-auto">
                        {props.prev && (<Button className="ps-5 pe-5 me-3" variant="secondary"
                                                onClick={props?.prev}>{props.prevLabel || 'Back'}</Button>)}
                        {props.next && (
                            <HoverTooltip
                                hidden={!props.nextDisabled || !props.nextDisabledTooltip}
                                text={props.nextDisabledTooltip || ''}>
                                <Button className="ps-5 pe-5" variant="primary"
                                        disabled={props.nextDisabled}
                                        onClick={props?.next}>{props.nextLabel || 'Next'}
                                </Button>
                            </HoverTooltip>)}
                    </div>
                </div>
            </NamedCard>
        </FullContainer>

    </>)
}