import React, {useEffect, useState} from "react";
import {Title} from "../../components/Title";
import {organizationState, organizationUuidState} from "../../store";
import {FullContainer} from "../../components/containers/FullContainer";
import {Section} from "../../components/Section";
import {Col, Row} from "react-bootstrap";
import {ActionButton} from "../../components/ActionButton";
import {NullableOption, Team, Tool} from "../../interfaces";
import {useRoles} from "../../hooks/UsePermissions";
import {useAlerts} from "../../hooks/useAlerts";
import {deleteTeams, getTeams, saveTeams} from "../../api/teams";
import {TEAM_OPTIONS, TOOL_OPTIONS} from "../../utils/Constants";
import CreatableSelect from "react-select/creatable";
import {deleteTools, getTools, saveTools} from "../../api/tools";
import makeAnimated from "react-select/animated";
import {useAtom} from "jotai/react";
import {NamedCard} from "../home-view/NamedCard";

const animatedComponents = makeAnimated();
const customComponents = {
    ...animatedComponents,
    DropdownIndicator: () => null,
    IndicatorSeparator: () => null,
};

export function OrganizationSettingsView() {
    const [organization] = useAtom(organizationState)
    const [organizationUuid] = useAtom(organizationUuidState);
    const [teams, setTeams] = useState<Team[]>([])
    const [isOwner] = useRoles()
    const [selectedTeams, setSelectedTeams] = useState<NullableOption[]>([])
    const [teamOptions, setTeamOptions] = useState<NullableOption[]>([])
    const [tools, setTools] = useState<Tool[]>([])
    const [selectedTools, setSelectedTools] = useState<NullableOption[]>([])
    const [toolOptions, setToolOptions] = useState<NullableOption[]>([])
    const {addError} = useAlerts()

    useEffect(() => {
        getTeams()
            .then(data => setTeams(data.filter((item: Team) => item.active)))
            .catch(error => addError(error))
    }, [organizationUuid]);

    useEffect(() => {
        getTools()
            .then(data => setTools(data.filter((item: Tool) => item.active && item.name !== 'None')))
            .catch(error => addError(error))
    }, [organizationUuid]);

    useEffect(() => {
        setSelectedTools([...tools.map(tool => ({value: tool.name, label: tool.name, uuid: tool.uuid}))])
        const toolOptions = tools.map(tool => ({value: tool.name, label: tool.name, uuid: tool.uuid}))
        const additionalOptions = TOOL_OPTIONS.filter(tool => !toolOptions.some(option => option.label === tool.label))
        setToolOptions([...toolOptions, ...additionalOptions])
    }, [tools]);

    useEffect(() => {
        setSelectedTeams([...teams.map(team => ({value: team.name, label: team.name, uuid: team.uuid}))])
        const teamOptions = teams.map(team => ({value: team.name, label: team.name, uuid: team.uuid}))
        const additionalOptions = TEAM_OPTIONS.filter(team => !teamOptions.some(option => option.label === team.label))
        setTeamOptions([...teamOptions, ...additionalOptions])
    }, [teams]);


    const handleSaveTeams = () => {
        const teamUuidsToBeDeleted = teams.filter(team => !selectedTeams.some(option => option?.label === team.name)).map(team => team.uuid)
        const teamNamesToBeAdded = selectedTeams.filter((option: any) => !option.uuid).map(option => option!.label)
        return saveTeams(teamNamesToBeAdded)
            .then(_ => deleteTeams(teamUuidsToBeDeleted))
            .then(_ => getTeams())
            .then(data => setTeams(data.filter((item: Team) => item.active)));
    };

    const handleSaveTools = () => {
        const toolUuidsToBeDeleted = tools.filter(tool => !selectedTools.some(option => option?.label === tool.name)).map(tool => tool.uuid)
        const toolNamesToBeAdded = selectedTools.filter((option: any) => !option.uuid).map(option => option!.label)
        return saveTools(toolNamesToBeAdded)
            .then(_ => deleteTools(toolUuidsToBeDeleted))
            .then(_ => getTools())
            .then(data => setTools(data.filter((item: Tool) => item.active && item.name !== 'None')));
    };

    const handleSave = (done: () => void) => {
        Promise.all([handleSaveTools(), handleSaveTeams()])
            .then(() => done())
            .catch(error => addError(error));
    }

    return (<FullContainer width={50}>
        <Title title={"Organization settings"} subtitle={organization?.name} className="mb-5"/>
        <Section>
            <NamedCard name="Details">
                <Row className="mt-5">
                    <Col md={2} className="d-flex flex-row align-items-center">Teams</Col>
                    <Col md={10}> <CreatableSelect
                        onKeyDown={e => e.key === "Enter" && e.stopPropagation()}
                        className="w-100"
                        noOptionsMessage={() => "Type to add more"}
                        options={teamOptions}
                        isDisabled={!isOwner}
                        components={customComponents}
                        closeMenuOnSelect={false}
                        isMulti={true}
                        onChange={setSelectedTeams}
                        value={selectedTeams}
                    />
                    </Col>
                </Row>
                <Row className="mt-3">
                    <Col md={2} className="d-flex flex-row align-items-center">Tools</Col>
                    <Col md={10}> <CreatableSelect
                        onKeyDown={e => e.key === "Enter" && e.stopPropagation()}
                        className="w-100"
                        noOptionsMessage={() => "Type to add more"}
                        options={toolOptions}
                        isDisabled={!isOwner}
                        components={customComponents}
                        closeMenuOnSelect={false}
                        isMulti={true}
                        onChange={setSelectedTools}
                        value={selectedTools}
                    />
                    </Col>
                </Row>
                <Row className="mt-3">
                    {isOwner && <div className="d-flex flex-row mt-3">
                        <div className="ms-auto">
                            <ActionButton variant="primary" handleClick={handleSave}/>
                        </div>
                    </div>}
                </Row>
            </NamedCard>

        </Section>

    </FullContainer>)
}