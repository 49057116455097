import {Col, Form, FormControl, Row} from "react-bootstrap";
import React, {useEffect, useRef, useState} from "react";
import WizardItemSelect from "../WizardItemSelect";
import {organizationUuidState, wizardProcessState} from "../../../store";
import {useNavigate, useParams} from "react-router";
import {TemplateListItem} from "../../../interfaces";
import WizardFrame from "../WizardFrame";
import ItemAddModal from "../ItemAddModal";
import {Section} from "../../../components/Section";
import initials from "initials";
import {useAlerts} from "../../../hooks/useAlerts";
import {createWizardProcess, getWizardProcess, getWizardProcessCodes, updateWizardProcess} from "../../../api/wizard";
import {getTemplate, getTemplates, saveTemplate} from "../../../api/templates";
import {useAtom} from "jotai/react";
import {useResetAtom} from "jotai/react/utils";
import {FullContainer} from "../../../components/containers/FullContainer";

export default function WizardTasks() {
    const [templateListItems, setTemplateListItems] = useState<TemplateListItem[]>([])
    const [wizardProcess, setWizardProcess] = useAtom(wizardProcessState)
    const resetWizardProcess = useResetAtom(wizardProcessState)

    const navigate = useNavigate();
    const params = useParams();
    const {addError} = useAlerts();
    const formRef = useRef<HTMLFormElement>(null);
    const [codes, setCodes] = useState<string[]>([])
    const [valid, setValid] = useState<boolean>(false)
    const organizationUuid = useAtom(organizationUuidState)[0]

    useEffect(() => {
        if (params.uuid) {
            getWizardProcess(params.uuid)
                .then(data => {
                    setWizardProcess(data);
                })
                .then(_ => getTemplates())
                .then(data => setTemplateListItems(data))
                .catch(error => addError(error))
        } else {
            getTemplates()
                .then(data => {
                    setTemplateListItems(data);
                    return data[0].uuid;
                })
                .then(uuid => getTemplate(uuid))
                .catch(error => addError(error))
        }
    }, [addError, params.uuid, setWizardProcess, organizationUuid]);

    useEffect(() => {
        getWizardProcessCodes()
            .then(data => setCodes(data))
            .catch(addError)
    }, [addError, organizationUuid]);

    const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setWizardProcess(prev => ({
            ...prev,
            name: event.currentTarget.value,
        }))
    }

    const generateNewCode = (candidate: string): string => {
        if (!codes.includes(candidate)) {
            return candidate;
        }
        const newCode = candidate.substring(0, 5) + Math.floor(Math.random() * 10);
        return generateNewCode(newCode);
    }

    const handleNameBlur = () => {
        if (wizardProcess.name) {
            setWizardProcess(prev => {
                const newCode = generateNewCode(initials(prev.name).substring(0, 6).toUpperCase())
                return ({
                    ...prev,
                    code: !prev.code ? newCode : prev.code
                });
            })
        }
    }

    const handleCodeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setWizardProcess(prev => ({
            ...prev,
            code: event.currentTarget.value?.toUpperCase()
        }))
    }

    const handleSelectTemplateUuid = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const templateUuid = event.target.value as string;
        getTemplate(templateUuid)
            .then(data => {
                setWizardProcess(prev => ({
                    ...prev,
                    templateUuid: templateUuid!,
                    tasks: data.tasks,
                    tools: data.tools,
                    teams: data.teams,
                }))
            })
            .catch(error => addError(error))
    }

    useEffect(() => setValid(formRef!.current!.checkValidity()
            && wizardProcess.tasks?.some(task => task.selected))
        , [wizardProcess.tasks, wizardProcess.name, wizardProcess.code, wizardProcess.templateUuid]);

    const handleClickNext = () => {
        if (!valid) {
            formRef.current!.reportValidity()
            return;
        }
        if (wizardProcess.uuid) {
            updateWizardProcess(wizardProcess)
                .then(_ => navigate(`/wizard/teams-and-tools/${wizardProcess.uuid}`))
                .catch(error => addError(error))
        } else {
            createWizardProcess(wizardProcess)
                .then(data => data.wizardProcessUuid)
                .then(uuid => navigate(`/wizard/teams-and-tools/${uuid}`))
                .catch(error => addError(error))
        }
    }

    const handleAddTemplate = (name: string) => {
        saveTemplate(name).then(resp => {
            const newTemplate = {uuid: resp.uuid, name, tasks: [], tools: [], teams: []}
            setTemplateListItems(prev => [...prev, newTemplate])
            setWizardProcess(prev => ({
                ...prev,
                templateUuid: resp.uuid,
                tasks: [],
                tools: [],
                teams: []
            }))
        }).catch(error => addError(error))
    }

    return (
        <FullContainer width={50}>
            <WizardFrame next={handleClickNext}
                         title="Let's get started ..."
                         nextDisabledTooltip={"Select at least one task"}
                         nextDisabled={!wizardProcess.tasks?.some(task => task.selected)}
                         prev={() => resetWizardProcess()}
                         prevLabel={'Reset'}

            >
                <Section>
                    <Form ref={formRef} onSubmit={handleClickNext}>
                        <Row>
                            <Col md={4} className="d-flex flex-row align-items-center">
                                Process name
                            </Col>
                            <Col md={8}>
                                <FormControl
                                    value={wizardProcess.name}
                                    type="text"
                                    required={true}
                                    minLength={3}
                                    maxLength={100}
                                    onBlur={handleNameBlur}
                                    onChange={handleNameChange}
                                />
                            </Col>
                        </Row>
                        <Row className="mt-3">
                            <Col md={4} className="d-flex flex-row align-items-center">
                                Project code
                            </Col>
                            <Col md={8}>
                                <FormControl
                                    value={wizardProcess.code}
                                    type="text"
                                    pattern={'[A-Z0-9]*'}
                                    required={true}
                                    minLength={2}
                                    maxLength={7}
                                    onChange={handleCodeChange}
                                />
                            </Col>
                        </Row>
                        <Row className="mt-3">
                            <Col md={4} className="d-flex flex-row align-items-center">
                                Template
                            </Col>
                            <Col md={8}>
                                <div className="d-flex flex-row">
                                    <Form.Select
                                        className="me-3"
                                        value={wizardProcess.templateUuid}
                                        onChange={handleSelectTemplateUuid}>
                                        <option hidden>Select</option>
                                        {templateListItems.map((item, index) => (
                                            <option key={`template_${index}`} value={item.uuid}>{item.name}</option>
                                        ))}
                                    </Form.Select>
                                    <ItemAddModal itemType="template" handleNewItem={handleAddTemplate}></ItemAddModal>
                                </div>
                            </Col>
                        </Row>
                    </Form>
                </Section>

                {wizardProcess.templateUuid &&
                    <Section className="mt-3" title="Add tasks that apply to your process">
                        <WizardItemSelect itemType="tasks"></WizardItemSelect>
                    </Section>
                }
            </WizardFrame>
        </FullContainer>
    )
}