import React, {useEffect, useState} from "react";
import {organizationUuidState} from "../store";
import {PendingInvitation} from "../interfaces";
import {useAlerts} from "../hooks/useAlerts";
import {acceptInvitation, declineInvitation, deleteInvitation, getInvitations} from "../api/invitations";
import {useSetAtom} from "jotai/react";
import {AgTable} from "./AgTable";
import {DateField} from "./DateField";
import {INVITATION_STATUSES, ROLES} from "../utils/Constants";
import {Button} from "react-bootstrap";
import {Section} from "./Section";

interface UserInvitationsParams {
    title?: string;
    onNoMorePendingLeft?: () => void;
}

export const UserInvitations = ({title, onNoMorePendingLeft}: UserInvitationsParams) => {
    const [invitations, setInvitations] = useState<PendingInvitation[]>([]);
    const setOrganizationUuid = useSetAtom(organizationUuidState);
    const {addError} = useAlerts()
    const [invitationsLoaded, setInvitationsLoaded] = useState<boolean>()
    const [rows, setRows] = useState<any[]>([])

    useEffect(() => {
        getInvitations()
            .then(data => setInvitations(data))
            .then(_ => setInvitationsLoaded(true))
            .catch(error => addError(error))
    }, [addError]);

    const handleAccept = (invitation: PendingInvitation) => {
        acceptInvitation(invitation.uuid!)
            .then(_ => getInvitations())
            .then(data => setInvitations(data))
            .then(_ => setOrganizationUuid(invitation.organizationUuid!))
            .catch(error => addError(error))
    }

    const handleDecline = (invitation: PendingInvitation) => {
        declineInvitation(invitation.uuid!)
            .then(_ => getInvitations())
            .then(data => setInvitations(data))
            .catch(error => addError(error))
    }

    const handleDelete = (invitation: PendingInvitation) => {
        deleteInvitation(invitation.uuid!)
            .then(_ => getInvitations())
            .then(data => setInvitations(data))
            .catch(error => addError(error))
    }

    useEffect(() => {
        if (invitations?.length > 0) {
            setRows(invitations.map(invitation => ({
                organization: invitation.organizationName,
                team: invitation.team.name,
                role: invitation.role,
                invitedBy: invitation.invitedBy,
                invitedAt: invitation.createdAt,
                status: invitation.status,
                invitation
            })))
        }
    }, [invitations]);

    return (
        <Section title={title}>
            <div className="mt-2" style={{height: '300px'}}>
                <AgTable
                    columnDefs={[
                        {
                            field: "organization",
                        },
                        {
                            field: "team",
                        },
                        {
                            field: "role",
                            cellRenderer: (params: any) => ROLES[params.value]
                        },
                        {
                            field: "invitedBy",
                            minWidth: 150,
                        },
                        {
                            field: "invitedAt",
                            minWidth: 150,
                            cellRenderer: (params: any) => <DateField date={params.value}/>
                        },
                        {
                            field: "status",
                            minWidth: 100,
                            cellRenderer: (params: any) => INVITATION_STATUSES[params.value]
                        },
                        {
                            headerName: 'Actions',
                            cellRenderer: (params: any) => {
                                const invitation = params.data.invitation;
                                return (
                                    <div className="d-flex flex-row gap-2 align-items-center h-100 row-hover-cell"
                                         style={{cursor: "pointer"}}>
                                        {invitation.status === 'PENDING' &&
                                            <Button variant="primary" size="sm"
                                                    onClick={() => handleAccept(invitation)}>Accept</Button>
                                        }
                                        {invitation.status === 'PENDING' &&
                                            <Button variant="danger" size="sm"
                                                    onClick={() => handleDecline(invitation)}>Decline</Button>
                                        }
                                        {invitation.status !== 'PENDING' &&
                                            <Button variant="dark" size="sm"
                                                    onClick={() => handleDelete(invitation)}>Delete</Button>
                                        }
                                    </div>
                                );
                            }
                        }
                    ]}
                    rowData={rows}
                    onGridReady={(params: any) => params.api.sizeColumnsToFit()}
                    rowClass="row-hover"
                    suppressCellFocus={true}
                />
            </div>
        </Section>
    );
}