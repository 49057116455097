import {OrganizationMember} from "../../../interfaces";
import React, {useMemo} from "react";
import {removeOrganizationMember} from "../../../api/organizations";
import {useConfirmModal} from "../../../components/modals/UseConfirmModal";
import {Button} from "react-bootstrap";

interface ActiveUserActionsProps {
    member: OrganizationMember,
    reload: () => void
    isCurrentUserOwner: boolean;
}

export const ActiveUserActions = ({isCurrentUserOwner, member, reload}: ActiveUserActionsProps) => {
    const {showModal} = useConfirmModal()

    const isMemberOwner = useMemo(() => member?.role === 'OWNER', [member?.role])

    const handleDeleteMember = (member: OrganizationMember) => {
        showModal(`Are you sure you want to remove ${member.name}?`,
            () => removeOrganizationMember(member.uuid!)
                .then(_ => reload()));
    }

    return (
        <div className="d-flex flex-row gap-2 align-items-center h-100 row-hover-cell">
            {isCurrentUserOwner && !isMemberOwner &&
                (<Button size="sm"
                         variant="danger"
                         onClick={() => handleDeleteMember(member)}
                         >Remove</Button>
                )}
        </div>
    )
}