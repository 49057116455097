import React from "react"

interface FullContainerProps {
    children: any
    className?: string
}

export function FullContainer(props: FullContainerProps) {
    return (<>
        <div style={{maxWidth: '1600px', marginLeft: 'auto', marginRight: 'auto'}}
             className={`d-flex flex-column mt-5 mb-5 ps-5 pe-5 ${props.className}`}>
            {props.children}
        </div>
    </>)
}