import React from "react";
import {Toast, ToastContainer} from "react-bootstrap";
import {useAtom, useAtomValue} from "jotai/react";
import {toastMessageState, toastShowState} from "../../store";

export const ToastMessage = () => {
    const message = useAtomValue<string>(toastMessageState);
    const [show, setShow] = useAtom<boolean>(toastShowState);

    return (
        <ToastContainer position={"bottom-center"} className="mb-5">
            <Toast onClose={() => setShow(false)} show={show} delay={3000} autohide>
                <Toast.Body className="text-center">{message}</Toast.Body>
            </Toast>
        </ToastContainer>
    );
}