import React from "react";
import {WizardTask, WizardTeam, WizardTool} from "../../../interfaces";
import {Button, ButtonGroup, Dropdown} from "react-bootstrap";
import {wizardProcessState} from "../../../store";
import {useAtom} from "jotai/react";

interface OrderedTaskRowProps {
    task: WizardTask,
}

export const OrderedTaskRow = ({task}: OrderedTaskRowProps) => {
    const [wizardProcess, setWizardProcess] = useAtom(wizardProcessState)
    const teams = wizardProcess.teams?.filter(team => team.selected)
    const tools = wizardProcess.tools?.filter(team => team.selected)

    const handleToolChange = (tool: WizardTool) => {
        setWizardProcess((prev) => ({
            ...prev,
            tasks: prev.tasks.map((item: WizardTask) => item.name === task.name ? {...item, tool} : item)
        }))
    }

    const handleTeamChange = (team: WizardTeam) => {
        setWizardProcess((prev) => ({
            ...prev,
            tasks: prev.tasks.map((item: WizardTask) => item.name === task.name ? {...item, team} : item)
        }))
    }

    return (
        <ButtonGroup className="p-2 d-flex flex-row align-items-center">
            <Button variant="primary" value={task.name} className="text-truncate w-50">
                <span>{task.name}</span>
            </Button>
            <Dropdown as={ButtonGroup} className="w-25">
                <Dropdown.Toggle variant="light" className="text-truncate w-100">
                    <span>{task?.team?.name || 'Select team'}</span>
                </Dropdown.Toggle>
                <Dropdown.Menu>
                    {teams.map((item: WizardTeam) =>
                        <Dropdown.Item
                            onClick={() => handleTeamChange(item)}
                            key={`team_${item.name}`}>{item.name}</Dropdown.Item>)}
                </Dropdown.Menu>
            </Dropdown>
            <Dropdown as={ButtonGroup} className="w-25">
                <Dropdown.Toggle variant="light" className="text-truncate w-100">
                    <span>{task?.tool?.name || 'Select tool'}</span>
                </Dropdown.Toggle>
                <Dropdown.Menu>
                    {tools.map((item: WizardTool) =>
                        <Dropdown.Item
                            onClick={() => handleToolChange(item)}
                            key={`tool_${item.name}`}>{item.name}</Dropdown.Item>)}
                    <Dropdown.Item onClick={() => handleToolChange({name: 'None', selected: true})}>None</Dropdown.Item>
                </Dropdown.Menu>
            </Dropdown>
        </ButtonGroup>
    );
}