import {UserOrganization} from "../../interfaces";
import React from "react";
import {Button} from "react-bootstrap";
import {useSetAtom} from "jotai/react";
import {organizationUuidState} from "../../store";

interface UserOrganizationActionsProps {
    organization: UserOrganization,
    handleLeave: (organization: UserOrganization) => void,
    handleDelete: (organization: UserOrganization) => void,
}

export const UserOrganizationActions = (props: UserOrganizationActionsProps) => {
    const setOrganizationUuid = useSetAtom(organizationUuidState);

    return (
        <div className="d-flex flex-row gap-2 align-items-center h-100 row-hover-cell">
            {props.organization?.roles.includes('OWNER') &&
                <Button size="sm" onClick={() => props.handleDelete(props.organization)}
                        variant="danger">Delete</Button>}
            {props.organization?.roles.includes('MEMBER') &&
                <Button
                    size="sm"
                    onClick={() => props.handleLeave(props.organization)}
                    variant="dark">Leave</Button>}
                <Button
                    size="sm"
                    onClick={() => setOrganizationUuid(props.organization.uuid)}
                    variant="primary">Select</Button>
        </div>)
}