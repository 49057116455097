import {FullContainer} from "../../components/containers/FullContainer";
import React, {useEffect, useState} from "react";
import {getBillingPlans, getBillingStatus, GetBillingStatusResponse} from "../../api/billing";
import {useAlerts} from "../../hooks/useAlerts";
import {Title} from "../../components/Title";
import {Section} from "../../components/Section";
import {BillingPlan} from "../../interfaces";
import {BillingPlanCard} from "./BillingPlanCard";
import {organizationUuidState} from "../../store";
import {useRoles} from "../../hooks/UsePermissions";
import {useAtom} from "jotai/react";
import {LoadingSpinner} from "../Loading";
import {CenterContainer} from "../../components/containers/CenterContainer";

export const SubscriptionView = () => {

    const [billingStatus, setBillingStatus] = useState<GetBillingStatusResponse | null>(null)
    const organizationUuid = useAtom(organizationUuidState)[0]
    const {addError, addInfo} = useAlerts()
    const [billingPlans, setBillingPlans] = useState<BillingPlan[]>([])
    const [isOwner] = useRoles()

    useEffect(() => {
        getBillingPlans()
            .then(data => setBillingPlans(data.plans))
            .catch(addError)
    }, [addError, organizationUuid])

    useEffect(() => {
        getBillingStatus()
            .then(data => setBillingStatus(data))
            .catch(addError)
    }, [addError, isOwner, organizationUuid])


    useEffect(() => {
        if (!isOwner && billingStatus?.billingStatus === 'NONE') {
            addInfo("No valid billing plan found. Contact organization administrator to subscribe.")
        }
    }, [addError, addInfo, billingStatus, isOwner, organizationUuid])

    return (
        <FullContainer>
            <Title
                title={billingStatus?.billingStatus === 'NONE' ? 'Start using ProcessPlot by choosing a plan' : 'Subscription'}
                className={"mb-3"}></Title>
            <Section>
                <div className="d-flex flex-row align-items-center" style={{width: "900px", height: "500px"}}>
                    {billingPlans.length === 0 &&
                        <div className="ms-auto me-auto"><LoadingSpinner/></div>}
                    {billingPlans.map((billingPlan: BillingPlan) => (
                        <BillingPlanCard key={billingPlan.code}
                                         current={!!billingStatus?.serviceAllowed && (billingStatus?.code === billingPlan.code)}
                                         viewOnly={!isOwner}
                                         plan={billingPlan}/>
                    ))}
                </div>
                <div className="small mt-5">
                    <span>By subscribing to the services of ProcessPlot you agree to our <a className="link-primary"
                                                                                            href="https://processplot.com/terms-of-service/"
                                                                                            target="_blank">Terms of Service</a>.<br/></span>
                    <span>ProcessPlot has the right to limit unlimited usage plan if any kind of system abuse is detected.</span>
                </div>
            </Section>
        </FullContainer>
    );
}