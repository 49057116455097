import {WizardProcess} from "../interfaces";
import {organizationUuidState} from "../store";
import fetcher from "./fetcher";
import {store} from "../index";

export const updateWizardProcess = (process: WizardProcess): Promise<{
    wizardProcessUuid: string,
    processUuid: string
}> => {
    const organizationUuid = store.get(organizationUuidState)
    return fetcher('PUT', `/wizard/process/${process.uuid}`, organizationUuid, {
        process: {
            templateUuid: process.templateUuid,
            name: process.name,
            code: process.code,
            teams: process.teams,
            tools: process.tools,
            tasks: process.tasks,
            ...(process.status && {status: process.status})
        }
    });
}

export const getWizardProcess = (uuid: string): Promise<WizardProcess> => {
    const organizationUuid = store.get(organizationUuidState)
    return fetcher('GET', `/wizard/process/${uuid}`, organizationUuid).then(resp => resp.process)
}

export const getWizardProcessList = (): Promise<WizardProcess[]> => {
    const organizationUuid = store.get(organizationUuidState)
    return fetcher('GET', `/wizard/process/list`, organizationUuid).then(result => result.processes);
}

export const deleteWizardProcess = (uuid: string): Promise<WizardProcess> => {
    const organizationUuid = store.get(organizationUuidState)
    return fetcher('DELETE', `/wizard/process/${uuid}`, organizationUuid);
}

export const getWizardProcessCodes = (): Promise<string[]> => {
    const organizationUuid = store.get(organizationUuidState)
    return fetcher('GET', `/wizard/process/codes`, organizationUuid);
}

export const createWizardProcess = (process: WizardProcess): Promise<{
    wizardProcessUuid: string,
    processUuid: string
}> => {
    const organizationUuid = store.get(organizationUuidState)
    return fetcher('POST', '/wizard/process', organizationUuid, {
        process: {
            templateUuid: process.templateUuid,
            name: process.name,
            code: process.code,
            teams: process.teams,
            tools: process.tools,
            tasks: process.tasks
        }
    });
}
