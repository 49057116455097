import {Table} from "react-bootstrap";
import {PageData, TaskInfo} from "../../interfaces";
import {ProjectTaskStatusBadge} from "../project-view/task/ProjectTaskStatus";
import {DateField} from "../../components/DateField";
import React, {useCallback, useEffect, useState} from "react";
import {getUpcomingTeamTasks} from "../../api/projects";
import {PageRow} from "../../components/PageRow";
import {useAlerts} from "../../hooks/useAlerts";
import {useNavigate} from "react-router";
import {NamedCard} from "./NamedCard";
import {organizationUuidState} from "../../store";
import {useAtom} from "jotai/react";

export default function TeamUpcomingTasks() {

    const [pageData, setPageData] = useState<PageData<TaskInfo> | null>(null)
    const {addError} = useAlerts()
    const [currentPage, setCurrentPage] = useState<number>(0)
    const navigate = useNavigate();
    const organizationUuid = useAtom(organizationUuidState)[0]

    const fetchTeamUpcomingTasks = useCallback(() => {
        getUpcomingTeamTasks(5, currentPage)
            .then(data => setPageData(data))
            .catch(error => addError(error))
    }, [addError, currentPage]);

    useEffect(() => {
        fetchTeamUpcomingTasks();
    }, [addError, fetchTeamUpcomingTasks, organizationUuid]);

    return (<NamedCard name={"Your team upcoming tasks"} className={"mb-3"}>
        <Table>
            <thead>
            <tr>
                <th>Name</th>
                <th>Project</th>
                <th>Code</th>
                <th>Status</th>
                <th>Est. start at</th>
            </tr>
            </thead>
            <tbody>
            {pageData?.items.map((item: TaskInfo) =>
                <tr key={`team_upcoming_${item.uuid}`} className="pointer link-primary"
                    onClick={() => navigate(`/project/${item.projectUuid}`)}>
                    <td style={{maxWidth: '300px'}} className="truncate">{item.task}</td>
                    <td>{item.project}</td>
                    <td>{item.projectCode}</td>
                    <td><ProjectTaskStatusBadge status={item.status}/></td>
                    <td><DateField date={item.startAt}/></td>
                </tr>
            )}
            </tbody>
        </Table>
        <PageRow id="team-upcoming-tasks" {...pageData!} onPageChange={setCurrentPage}/>
    </NamedCard>);
}