import React from "react";
import {Button, Card} from "react-bootstrap";
import {BillingPlan} from "../../interfaces";
import {PLAN_NAMES} from "../../utils/Constants";
import capitalize from "lodash-es/capitalize"
import {useNavigate} from "react-router";
import {UsersIcon} from "../../components/svg/UsersIcon";
import {ProcessesIcon} from "../../components/svg/ProcessesIcon";
import {ProjectsIcon} from "../../components/svg/ProjectsIcon";
import {AnalyticsIcon} from "../../components/svg/AnalyticsIcon";

interface BillingPlanCardProps {
    plan: BillingPlan,
    current: boolean,
    viewOnly: boolean
}

export const BillingPlanCard = ({plan, current, viewOnly}: BillingPlanCardProps) => {

    const navigate = useNavigate();

    const formatNumber = (value: number) => {
        if (value < 0) return "Unlimited"
        return value;
    }

    const handleClick = () => {
        if (!viewOnly) {
            navigate(`/checkout?plan=${plan.code}`);
        }
    };

    return (<Card onClick={handleClick} className="text-center m-2 pointer"
                  style={{width: '280px', height: '453px'}}>
        <Card.Body>
            <div className="d-flex flex-column align-items-center justify-content-between h-100">
                <div className="mt-3">
                    <span className="price-title">{PLAN_NAMES[plan.code]}</span>
                </div>
                <div className="mb-3 mt-3 small">{plan.description}</div>
                <div className="mb-4 mt-auto">
                    <div className="d-flex align-items-center"><UsersIcon
                        className="icon-style-sm me-1"/>{`${formatNumber(plan.users)} users`}</div>
                    <div className="d-flex align-items-center"><ProcessesIcon
                        className="icon-style-sm me-1"/>{`${formatNumber(plan.processes)} processes`}</div>
                    <div className="d-flex align-items-center"><ProjectsIcon
                        className="icon-style-sm me-1"/>{`${formatNumber(plan.activeProjects)} active projects`}</div>
                    <div className="d-flex align-items-center"><AnalyticsIcon
                        className="icon-style-sm me-1"/>{`${capitalize(plan.analytics)} analytics`}</div>
                </div>
                <div className="mb-4">
                    <span className="price-text">{`${plan.price}`}</span> <span
                    className="text-muted">{`${plan.currency.toUpperCase()} / ${plan.period}`}</span>
                </div>
                {!viewOnly &&
                    <div className="mb-4">
                        {current ?
                            <Button variant="success" disabled={true}>Manage plan</Button> :
                            <Button>Select plan</Button>}
                    </div>
                }
            </div>
        </Card.Body>
    </Card>);
}