import React, {useEffect, useState} from "react";
import {useLocation, useNavigate} from "react-router";

import {organizationUuidState, userState} from "../store";
import {getUser} from "../api/users";
import {SubscriptionView} from "../routes/subscription/SubscriptionView";
import {LoadingSpinner} from "../routes/Loading";
import {Role} from "../interfaces";
import {useAtom} from "jotai/react";

interface SecuredProps {
    children: any;
    roles?: Role[];
    disablePaywall?: boolean
}

export default function NavigationGuard(props: SecuredProps) {
    const [user, setUser] = useAtom(userState)
    const [organizationUuid, setOrganizationUuid] = useAtom(organizationUuidState)
    const navigate = useNavigate();
    const location = useLocation();
    const [accessPermitted, setAccessPermitted] = useState<boolean>(false)
    const [paywalled, setPaywalled] = useState<boolean>(false)
    const [loading, setLoading] = useState<boolean>(true)

    useEffect(() => {
        getUser().then(data => {
            setUser(data)
            if (!organizationUuid && data.organizations?.length) {
                setOrganizationUuid(data.organizations[0].uuid)
            }
        }).catch(_ => {
            handleSignIn()
        });
    }, [location.pathname, navigate, setUser, setOrganizationUuid]);

    useEffect(() => {
        if (user?.email) {
            const isLoggedIn = !!user?.email;
            const userRoles = user?.organizations.find(org => org.uuid === organizationUuid)?.roles || []
            const hasRequiredRole = !props.roles || props.roles.length === 0 || props.roles?.some(role => userRoles.includes(role))
            const isPaywalled = !props.disablePaywall && !user?.organizations.find(org => org.uuid === organizationUuid)?.activeSubscription;
            setPaywalled(isPaywalled)
            setAccessPermitted(isLoggedIn && hasRequiredRole)
            setLoading(false)
        }
    }, [user?.email, user?.organizations, navigate, organizationUuid, props.disablePaywall, props.roles, location.pathname]);


    const handleSignIn = () => {
        const currentPath = window.location.pathname + window.location.search;
        window.location.href = `${process.env.REACT_APP_API_URL}/login?redirect=${encodeURIComponent(currentPath)}`;
    };

    if (paywalled) return <SubscriptionView/>;

    return <>{accessPermitted && <>{props.children}</>}</>;
}