import {useRouteError} from "react-router-dom";
import React from "react";
import {errorState} from "../store";
import {FullContainer} from "../components/containers/FullContainer";
import {useAtom} from "jotai/react";

export default function ErrorView() {
    const routeError: any = useRouteError();
    const appError: any = useAtom(errorState)[0]
    const error = appError || routeError;
    return (
        <FullContainer>
            <div>
                <h1>Oops!</h1>
                <p>Sorry, an unexpected error has occurred.</p>
                <p>
                    <i>{error?.statusText || error?.message}</i>
                </p>
            </div>
        </FullContainer>
    );
}