import React from "react";
import {Badge} from "react-bootstrap";
import {TASK_STATUS} from "../../../utils/Constants";
import {ProjectTaskStatus} from "../../../interfaces";

interface ProjectTaskStatusProps {
    status: ProjectTaskStatus;
}

export const ProjectTaskStatusBadge = (props: ProjectTaskStatusProps) => {

    const getVariant = (status: ProjectTaskStatus) => {
        switch (status) {
            case 'ON_TRACK':
                return 'primary';
            case 'NOT_STARTED':
                return 'info';
            case 'SKIPPED':
            case 'CANCELLED':
            case 'ON_HOLD':
                return 'dark';
            case 'DELAYED':
                return 'danger';
            case 'COMPLETED':
                return 'success';
        }
    }

    return (<>
        <Badge bg={getVariant(props.status)}>{TASK_STATUS[props.status]}</Badge>
    </>)
}