import React, {useEffect, useMemo, useState} from "react";
import {Process, WizardProcess} from "../../interfaces";
import {useNavigate} from "react-router";
import {organizationUuidState, wizardProcessState} from "../../store";
import {Title} from "../../components/Title";
import {Button} from "react-bootstrap";
import {FullContainer} from "../../components/containers/FullContainer";
import {useAlerts} from "../../hooks/useAlerts";
import {deleteWizardProcess, getWizardProcessList} from "../../api/wizard";
import {deleteProcess, getProcessList} from "../../api/process";
import {useConfirmModal} from "../../components/modals/UseConfirmModal";
import {useResetAtom} from "jotai/react/utils";
import {useAtom} from "jotai/react";
import {AgTable} from "../../components/AgTable";
import {PROCESS_STATUS} from "../../utils/Constants";
import {DateField} from "../../components/DateField";

export default function ProcessListView() {

    const [wizardProcessList, setWizardProcessList] = useState<WizardProcess[]>([])
    const [processList, setProcessList] = useState<Process[]>([])
    const organizationUuid = useAtom(organizationUuidState)[0]
    const navigate = useNavigate();
    const resetProcess = useResetAtom(wizardProcessState)
    const {addError} = useAlerts();
    const {showModal} = useConfirmModal()

    useEffect(() => {
        getWizardProcessList()
            .then(result => setWizardProcessList(result || []))
            .catch(error => addError(error))
    }, [addError, organizationUuid]);

    useEffect(() => {
        getProcessList()
            .then(result => setProcessList(result || []))
            .catch(error => addError(error))
    }, [addError, organizationUuid]);


    const handleWizardSelect = (process: WizardProcess) => {
        navigate(`/wizard/tasks/${process.uuid}`)
    }

    const handleSelect = (process: Process) => {
        navigate(`/process/${process.uuid}`);
    }

    const handleWizardDelete = (process: WizardProcess) => {
        const deleteAction = () => deleteWizardProcess(process.uuid)
            .then(_ => getWizardProcessList())
            .then(result => setWizardProcessList(result || []))
            .catch(error => addError(error));
        showModal(`Are you sure you want to delete ${process.name}?`, deleteAction);
    }

    const handleDelete = (process: Process) => {
        const deleteAction = () => deleteProcess(process.uuid)
            .then(_ => getProcessList())
            .then(result => setProcessList(result || []))
            .catch(error => addError(error));
        showModal(`Are you sure you want to delete ${process.name}?`, deleteAction);
    }

    const rows = useMemo(() => {
        const processRows = processList.map(process => ({
            name: process.name,
            code: process.code,
            status: process.status,
            createdBy: process.createdBy,
            createdAt: new Date(process.createdAt),
            process: process,
            type: 'process',
            addRow: false
        }));
        const wizardProcessRows = wizardProcessList.map(process => ({
            name: process.name,
            code: process.code,
            status: process.status,
            createdBy: process.createdBy,
            createdAt: new Date(process.createdAt!),
            process: process,
            type: 'wizard',
            addRow: false
        }));
        return [
            ...processRows,
            ...wizardProcessRows];
    }, [processList, wizardProcessList]);

    return (<>
        <FullContainer>
            <Title title="Processes" className="mb-4"/>
            <div style={{width: '1200px'}}>
                <AgTable
                    className="hide-floating-filter-icon pinned-row"
                    columnDefs={[
                        {
                            field: "name",
                            width: 250,
                            colSpan: (params: any) => params.data.addRow ? 7 : 1,
                            cellRenderer: (params: any) => params.data.addRow ?
                                <div className="d-flex justify-content-center align-items-center h-100">
                                    <Button size="sm" onClick={() => {
                                        resetProcess();
                                        navigate('/wizard/tasks')
                                    }}>Add New</Button></div> :
                                params.value,
                            filter: "agTextColumnFilter",
                            filterParams: {
                                debounceMs: 200,
                            },
                        },
                        {
                            field: "code",
                            width: 120,
                            filter: "agTextColumnFilter",
                            filterParams: {
                                debounceMs: 200,
                            },

                        },
                        {
                            field: "createdBy",
                            width: 200,
                            filter: "agTextColumnFilter",
                            filterParams: {
                                debounceMs: 200,
                            },
                        },
                        {
                            field: "createdAt",
                            width: 200,
                            cellRenderer: (params: any) => <DateField date={params.value}/>,
                            filter: "agDateColumnFilter",
                            filterParams: {
                                debounceMs: 200,
                            },
                        },
                        {
                            field: "status",
                            width: 120,
                            valueFormatter: (params: any) => PROCESS_STATUS[params.value],
                            filter: "agTextColumnFilter",
                            filterParams: {
                                debounceMs: 200,
                            },
                        },
                        {
                            headerName: 'Actions',
                            cellRenderer: (params: any) => {
                                return (<div className="d-flex flex-row gap-2 align-items-center h-100 row-hover-cell">
                                    <Button
                                        size="sm"
                                        onClick={() => {
                                            if(params.data.type === 'process') {
                                                console.log(params.data)
                                                handleSelect(params.data.process)
                                            } else {
                                                handleWizardSelect(params.data.process)
                                            }
                                        }}>View</Button>
                                    <Button
                                        size="sm"
                                        variant="danger"
                                        onClick={() => {
                                            if(params.data.type === 'process') {
                                                handleDelete(params.data.process)
                                            } else {
                                                handleWizardDelete(params.data.process)
                                            }
                                        }}>Delete</Button>
                                </div>);
                            },
                            width: 232,
                        }]}
                    rowData={rows}
                    domLayout='autoHeight'
                    rowClass="row-hover"
                    onGridSizeChanged={(params:any) => params.api.sizeColumnsToFit()}
                    pagination={true}
                    paginationPageSizeSelector={[10,20]}
                    paginationPageSize={10}
                    pinnedBottomRowData={[{
                        name: "",
                        code: "",
                        status: "",
                        type: "",
                        process: null,
                        addRow: true,
                    }]}
                    suppressCellFocus={true}
                />
            </div>
        </FullContainer>
    </>)
}

