import {Form} from "react-bootstrap";
import Select from "react-select";
import {Invitation, SelectOption} from "../../../interfaces";
import EnvelopeIcon from "@heroicons/react/24/solid/EnvelopeIcon";
import XMarkIcon from "@heroicons/react/24/solid/XMarkIcon";
import React, {useMemo, useRef} from "react";
import makeAnimated from "react-select/animated";
import {ROLES} from "../../../utils/Constants";

const plainSelect = {
    control: (provided: any) => ({
        ...provided,
        border: "none",
        background: "transparent",
        boxShadow: "none",
        minHeight: "auto",
        paddingLeft: 0,
        width: "auto",
        fontSize: "16px"
    }),
    dropdownIndicator: (provided: any) => ({
        ...provided,
        padding: 4,
        color: "#000",
    }),
    indicatorSeparator: () => ({
        display: "none",
    }),
    valueContainer: (provided: any) => ({
        ...provided,
        paddingLeft: 0,
    }),
};

const animatedComponents = makeAnimated();

interface NewUserInviteRowParams {
    invite: Invitation,
    isOwner: boolean,
    index: number,
    handleInviteChange: (index: number, invitation: Partial<Invitation>, target?: any) => void,
    handleCreateInvitation: (invitation: Invitation, index: number) => void,
    teamOptions: SelectOption[],
    handleRemoveInvite: (index: number) => void,
    forbiddenEmails: (string | undefined)[]
}

export const NewInvitationRow = ({
                                     invite,
                                     handleInviteChange,
                                     index,
                                     isOwner,
                                     handleCreateInvitation,
                                     teamOptions,
                                     handleRemoveInvite,
                                     forbiddenEmails
                                 }: NewUserInviteRowParams) => {

    const roleOptions = useMemo(() => isOwner ? [
        {value: 'MEMBER', label: ROLES['MEMBER']},
        {value: 'OWNER', label: ROLES['OWNER']}
    ] : [{value: 'MEMBER', label: ROLES['MEMBER']}], [isOwner]);

    const emailRef = useRef<HTMLInputElement>(null);

    const handleChangeEmail = (index: number, invitation: Partial<Invitation>) => {
        if (invitation.email && forbiddenEmails.includes(invitation.email)) {
            emailRef.current!.setCustomValidity('Cannot use this email')
            emailRef.current!.reportValidity();
        } else {
            emailRef.current!.setCustomValidity('')
        }
        handleInviteChange(index, invitation)
    }

    const handleCreateInvitationClick = (invitation: Invitation, index: number) => {
        if (invitation.email && forbiddenEmails.includes(invitation.email)) {
            emailRef.current!.setCustomValidity('Cannot use this email')
            emailRef.current!.reportValidity();
            return;
        }
        if (emailRef.current!.checkValidity()) {
            handleCreateInvitation(invitation, index)
        } else {
            emailRef.current!.reportValidity();
        }
    }

    return (
        <tr>
            <td className="align-middle">
                <Form.Control
                    ref={emailRef}
                    plaintext
                    data-form-type="other"
                    data-lpignore="true"
                    className="plain-input truncate-no-width"
                    type="email"
                    required={true}
                    autoComplete="false"
                    value={invite.email}
                    onChange={(event) => handleChangeEmail(index, {email: event.currentTarget.value})}
                    placeholder={`example@example.com`}
                />
            </td>
            <td className="align-middle">
                <Select
                    className="plain-input"
                    value={invite.team}
                    options={teamOptions}
                    required={true}
                    styles={plainSelect}
                    components={animatedComponents}
                    onChange={(team: SelectOption) => handleInviteChange(index, {team})}
                />
            </td>
            <td>
                <Select
                    className="plain-input"
                    value={invite.role}
                    options={roleOptions}
                    styles={plainSelect}
                    required={true}
                    components={animatedComponents}
                    onChange={(role: SelectOption) => handleInviteChange(index, {role})}
                />
            </td>
            <td className="align-middle">
                Unsent
            </td>
            <td className="align-middle">
                <div className="d-flex flex-row align-items-start"
                     style={{cursor: "pointer"}}>
                    <XMarkIcon className="icon-style pointer link-danger"
                               onClick={() => handleRemoveInvite(index)}/>
                    <EnvelopeIcon className="ms-3 icon-style pointer link-primary"
                                  onClick={() => handleCreateInvitationClick(invite, index)}/>

                </div>
            </td>
        </tr>
    )
}