import {organizationUuidState} from '../store';
import {Process, WizardProcess} from '../interfaces';
import fetcher from "./fetcher";
import {store} from "../index";

export const getProcessList = (): Promise<Process[]> => {
    const organizationUuid = store.get(organizationUuidState)
    return fetcher('GET', `/process/list`, organizationUuid)
}

export const getProcess = (uuid: string): Promise<Process> => {
    const organizationUuid = store.get(organizationUuidState)
    return fetcher('GET', `/process/${uuid}`, organizationUuid)
}

export const saveProcess = (process: Process): Promise<void> => {
    const organizationUuid = store.get(organizationUuidState)
    return fetcher('POST', `/process/${process.uuid}`, organizationUuid, {
        process: process.process,
        name: process.name,
        code: process.code
    })
}

export const deleteProcess = (uuid: string): Promise<WizardProcess> => {
    const organizationUuid = store.get(organizationUuidState)
    return fetcher('DELETE', `/process/${uuid}`, organizationUuid);
}







