import {Card, CardBody, CardTitle} from "react-bootstrap";
import React from "react";

interface NamedCardProps {
    className?: any;
    name?: any;
    children: any;
}

export const NamedCard = (props: NamedCardProps) => {
    return (<Card className={props.className || ''}>
        <CardBody>
            <CardTitle>{props.name}</CardTitle>
            {props.children}
        </CardBody>
    </Card>);
}