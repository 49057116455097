import {useModal} from "react-modal-hook";
import React, {useState} from "react";
import {ConfirmModal} from "./ConfirmModal";
import {Variant} from "react-bootstrap/types";


export const useConfirmModal = () => {

    const [modalText, setModalText] = useState<string>('')
    const [variant, setVariant] = useState<Variant | undefined>()
    const [modalAction, setModalAction] = useState<{ func: () => void }>({
        func: () => {
        }
    })

    const [show, hide] = useModal(() => (
        <ConfirmModal onAction={modalAction.func} onHide={hide} variant={variant}>
            <span style={{whiteSpace: 'pre-wrap'}}>{modalText}</span>
        </ConfirmModal>
    ), [modalText, modalAction.func, variant]);

    const showModal = (text: string, onConfirm: () => Promise<any>, variant?: Variant) => {
        setModalText(text);
        setVariant(variant)
        setModalAction({
            func: () => {
                onConfirm().then(_ => hide())
            }
        })
        show();
    }

    return {showModal}
}