import {Table} from "react-bootstrap";
import {PendingInvitationRow} from "./PendingInvitationRow";
import {Invitation, OrganizationMember, PendingInvitation, SelectOption, Team} from "../../../interfaces";
import React, {useEffect, useMemo, useState} from "react";
import {userState} from "../../../store";
import {NewInvitationRow} from "./NewInvitationRow";
import {Section} from "../../../components/Section";
import {useAlerts} from "../../../hooks/useAlerts";
import {getTeams} from "../../../api/teams";
import {getOrganizationInvitations} from "../../../api/organizations";
import {cancelInvitation, createInvitation, deleteInvitation} from "../../../api/invitations";
import {useAtom} from "jotai/react";

interface InvitedUsersParams {
    organizationUuid?: string,
    isOwner: boolean,
    members: OrganizationMember[]
}


export const InvitedUsersList = ({organizationUuid, isOwner, members}: InvitedUsersParams) => {
    const [existingInvites, setExistingInvites] = useState<PendingInvitation[]>([])
    const [teamOptions, setTeamOptions] = useState<SelectOption[]>([])
    const [newInvites, setNewInvites] = useState<Invitation[]>([]);
    const user = useAtom(userState)[0]
    const {addError} = useAlerts()

    useEffect(() => {
        getTeams()
            .then(data => setTeamOptions(data.filter((team: Team) => team.active).map((team: Team) => ({
                value: team.uuid,
                label: team.name
            }))))
            .catch(error => addError(error))
    }, [addError, organizationUuid]);

    useEffect(() => {
        getOrganizationInvitations()
            .then(data => setExistingInvites(data))
            .catch(error => addError(error))
    }, [addError, organizationUuid]);

    const forbiddenEmails = useMemo(() => [
        ...existingInvites.map(existingInvite => existingInvite.email),
        ...members.map(member => member.email)
    ], [existingInvites, members])

    const handleCancelInvitation = (invitation: PendingInvitation) => {
        cancelInvitation(invitation.uuid!)
            .then(_ => getOrganizationInvitations())
            .then(data => setExistingInvites(data))
            .catch(error => addError(error))
    }

    const handleDeleteInvitation = (invitation: PendingInvitation) => {
        deleteInvitation(invitation.uuid!)
            .then(_ => getOrganizationInvitations())
            .then(data => setExistingInvites(data))
            .catch(error => addError(error))
    }

    const handleCreateInvitation = (invitation: Invitation, index: number) => {
        createInvitation(invitation)
            .then(_ => getOrganizationInvitations())
            .then(data => setExistingInvites(data))
            .then(_ => handleRemoveInvite(index))
            .catch(error => addError(error))
    }

    const handleAddInvite = () => {
        setNewInvites([...newInvites, {
            email: '',
            team: teamOptions.length ? teamOptions[0] : null,
            role: {value: 'MEMBER', label: 'User'}
        }]);
    };

    const handleRemoveInvite = (index: number) => {
        setNewInvites(newInvites.filter((_, i) => i !== index));
    };

    const handleInviteChange = (index: number, invite: Partial<{
        email: string,
        team: SelectOption,
        role: SelectOption
    }>) => {
        const invites = [...newInvites]
        invites[index] = {...invites[index], ...invite}
        setNewInvites(invites);
    }

    const canRevoke = (invite: PendingInvitation) => {
        return isOwner || [invite.email, invite.invitedByEmail].includes(user?.email)
    }

    return (<Section title="Invitations">
        <Table className="fixed-table">
            <thead>
            <tr>
                <th>Email</th>
                <th>Team</th>
                <th>Role</th>
                <th>Status</th>
                <th>Actions</th>
            </tr>
            </thead>
            <tbody>
            {existingInvites.map(invite => <PendingInvitationRow
                key={invite.uuid}
                invitation={invite}
                canRevoke={canRevoke(invite)}
                handleCancel={handleCancelInvitation}
                handleDelete={handleDeleteInvitation}
            ></PendingInvitationRow>)}
            {newInvites.map((invite, index) =>
                <NewInvitationRow key={index}
                                  invite={invite}
                                  index={index}
                                  isOwner={isOwner}
                                  teamOptions={teamOptions}
                                  forbiddenEmails={forbiddenEmails}
                                  handleRemoveInvite={handleRemoveInvite}
                                  handleCreateInvitation={handleCreateInvitation}
                                  handleInviteChange={handleInviteChange}/>
            )}
            <tr>
                <td colSpan={5}
                    onClick={handleAddInvite}
                    className="text-center text-muted pointer">Click to add invite
                </td>
            </tr>
            </tbody>
        </Table>
    </Section>)
}