import React, {useState} from "react";
import {SidebarLink} from "./SidebarLink";
import {Nav} from "react-bootstrap";
import {SidebarOrganizationSelect} from "./SidebarOrganizationSelect";
import {
    Bars3Icon,
    ChevronDoubleLeftIcon,
    ChevronDoubleRightIcon,
    DocumentDuplicateIcon,
    DocumentIcon,
    HomeIcon,
    SparklesIcon
} from "@heroicons/react/24/outline";
import {ArrowTrendingUpIcon} from "@heroicons/react/24/solid";

interface SidebarProps {
    visible: boolean;
}

export const Sidebar = ({visible}: SidebarProps) => {
    const [collapsed, setCollapsed] = useState(false);

    return (<>{visible &&
        <div className="sticky-div sidebar hero-bg-sidebar"
             style={{
                 pointerEvents: visible ? 'initial' : 'none',
                 width: collapsed ? '60px' : '200px',
                 minWidth: collapsed ? '60px' : '200px',
                 transition: 'width 0.3s, min-width 0.3s',
                 whiteSpace: 'nowrap',
             }}
        >
            <button
                style={{
                    margin: '10px',
                    marginLeft: '12px',
                    background: 'none',
                    border: 'none',
                    cursor: 'pointer',
                }}
                onClick={() => setCollapsed(!collapsed)}
            >
                {collapsed ?
                    <ChevronDoubleRightIcon className="icon-style link-info"/> :
                    <ChevronDoubleLeftIcon className="icon-style link-info"/>
                }
            </button>
            <Nav className="flex-column">
                <SidebarOrganizationSelect collapsed={collapsed}/>
                <SidebarLink iconComponent={HomeIcon} label="Home" to="/home" collapsed={collapsed}/>
                <SidebarLink iconComponent={Bars3Icon} label="Processes" to="/process/list" collapsed={collapsed}/>
                <SidebarLink iconComponent={SparklesIcon} label="New Process" to="/wizard/tasks"
                             collapsed={collapsed}/>
                <SidebarLink iconComponent={DocumentDuplicateIcon} label="Saved Projects" to="/projects"
                             collapsed={collapsed}/>
                <SidebarLink iconComponent={DocumentIcon} label="New Project" to="/new-project" collapsed={collapsed}/>
                <SidebarLink iconComponent={ArrowTrendingUpIcon} label="Analytics" to="/analytics"
                             collapsed={collapsed}/>
            </Nav>
        </div>}
    </>)
};