import {Invitation, PendingInvitation} from "../interfaces";
import fetcher from "./fetcher";
import {organizationUuidState} from "../store";
import {store} from "../index";

export const getInvitation = (uuid: string): Promise<PendingInvitation> => {
    return fetcher('GET', `/invitation/${uuid}`);
}

export const getInvitations = (): Promise<PendingInvitation[]> => {
    return fetcher('GET', `/invitation/list`);
}


export const acceptInvitation = (uuid: string): Promise<void> => {
    return fetcher('PUT', `/invitation/${uuid}/accept`);
}

export const declineInvitation = (uuid: string): Promise<void> => {
    return fetcher('PUT', `/invitation/${uuid}/decline`);
}

export const cancelInvitation = (uuid: string): Promise<void> => {
    const organizationUuid = store.get(organizationUuidState)
    return fetcher('PUT', `/invitation/${uuid}/cancel`, organizationUuid);
}

export const createInvitation = (invitation: Invitation): Promise<void> => {
    const organizationUuid = store.get(organizationUuidState)
    return fetcher('POST', `/invitation`, organizationUuid, {
        email: invitation.email,
        team: invitation.team?.value,
        role: invitation.role.value,
    });
}

export const deleteInvitation = (uuid: string): Promise<void> => {
    return fetcher('DELETE', `/invitation/${uuid}`);
}