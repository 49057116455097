import {Button, Modal} from "react-bootstrap";
import React from "react";
import {Variant} from "react-bootstrap/types";

interface ConfirmationModalProps {
    children: React.ReactNode;
    variant?: Variant;
    onAction: () => void;
    onHide?: () => void;
}

export const ConfirmModal = (props: ConfirmationModalProps) => {

    return (<Modal show={true} backdrop={true} centered={true}>
        <Modal.Header onHide={props.onHide} closeButton>
            <div>
                <h5 className="mt-auto mb-auto">Confirm Action</h5>
            </div>
        </Modal.Header>
        <Modal.Body>
            {props.children}
        </Modal.Body>
        <Modal.Footer>
            <Button variant={'dark-outline'}
                    onClick={props.onHide}>{'Cancel'}</Button>
            <Button variant={props.variant || 'danger'}
                    onClick={() => props.onAction()}>{'Confirm'}</Button>
        </Modal.Footer>
    </Modal>)
}