import {ActiveUserActions} from "./ActiveUserActions";
import React, {useEffect, useMemo, useState} from "react";
import {OrganizationMember, Team} from "../../../interfaces";
import {Section} from "../../../components/Section";
import {userState} from "../../../store";
import {useAlerts} from "../../../hooks/useAlerts";
import {getOrganizationMembers, updateOrganizationMember} from "../../../api/organizations";
import {useAtom} from "jotai/react";
import {ROLES} from "../../../utils/Constants";
import {AgTable} from "../../../components/AgTable";
import {mapEditableCell} from "../../../components/data-table/util";
import {useToast} from "../../../components/toasts/UseToast";

interface ActiveUsersParams {
    organizationUuid?: string,
    isOwner: boolean,
    members: OrganizationMember[],
    setMembers: (value: any) => void,
    teams: Team[]
}

interface ActiveUserRow {
    name: string;
    email: string;
    team: string;
    role: string;
    member: OrganizationMember;
}

export const ActiveUsersList = ({isOwner, members, setMembers, teams}: ActiveUsersParams) => {

    const user = useAtom(userState)[0];
    const {addError} = useAlerts()
    const [rows, setRows] = useState<ActiveUserRow[]>([])
    const [setToast] = useToast()

    const reload = () => {
        getOrganizationMembers()
            .then(data => setMembers(data))
            .catch(error => addError(error));
    }

    useEffect(() => {
        setRows(members.map(member => ({
            name: `${member.name} ${user?.email === member.email ? '(you)' : ''}`,
            email: member.email,
            team: member.team?.uuid,
            role: member.role,
            member
        })));
    }, [members, user?.email]);

    const teamLabels = useMemo(() => Object.fromEntries(teams.map(team => [team.uuid, team.name])), [teams])
    const teamValues = useMemo(() => teams.map(team => team.uuid), [teams])

    const onCellEditRequest = (params: any) => {
        const changedRow = params.data;
        updateOrganizationMember({
            userUuid: changedRow.member.uuid,
            teamUuid: changedRow.team,
            role: changedRow.role
        }).then(_ => reload())
            .then(_ => setToast('Updated successfully'))
            .catch(addError);
    }

    const mapRoles = (params: any): any[] => {
        if (!isOwner) {
            return ["MEMBER"]
        }
        const currentRole = params.data.member.role;
        if (currentRole === 'OWNER') {
            return ["OWNER"]
        }
        return ["MEMBER", "OWNER"]
    };

    return (<Section title="Active users">
        <div className="mt-2" style={{width: '1040px'}}>
            <AgTable
                singleClickEdit={true}
                stopEditingWhenCellsLoseFocus={true}
                onCellValueChanged={onCellEditRequest}
                columnDefs={[
                    {
                        field: "name",
                        width: 200,
                    },
                    {
                        field: "email",
                        width: 250,
                    },
                    mapEditableCell({
                        field: "team",
                        width: 200,
                        values: teamValues,
                        labels: teamLabels,
                        editable: (params: any) => params.data.member.role === 'OWNER'
                    }),
                    mapEditableCell({
                        field: "role",
                        width: 100,
                        values: mapRoles,
                        labels: ROLES,
                        editable: (params: any) => params.data.member.role === 'OWNER'
                    }),
                    {
                        headerName: 'Actions',
                        cellRenderer: (params: any) => <ActiveUserActions isCurrentUserOwner={isOwner}
                                                                          member={params.data.member}
                                                                          reload={reload}/>,
                        width: 200,
                    }]}
                rowData={rows}
                domLayout='autoHeight'
                suppressCellFocus={true}
            />
        </div>
    </Section>)
}