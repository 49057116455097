import fetcher from "./fetcher";
import {organizationUuidState} from "../store";
import {BillingPlan, BillingPlanCode, BillingStatus, BillingUsage} from "../interfaces";
import {store} from "../index";

export interface CreateCheckoutSessionResponse {
    clientSecret: string | null;
    portalSessionUrl: string | null;
}

export const createCheckoutSession = (code: BillingPlanCode): Promise<CreateCheckoutSessionResponse> => {
    const organizationUuid = store.get(organizationUuidState)
    return fetcher('POST', `/billing/checkout`, organizationUuid, {
        code
    });
}

export interface CreatePortalSessionResponse {
    url: string;
}

export const createPortalSession = (): Promise<CreatePortalSessionResponse> => {
    const organizationUuid = store.get(organizationUuidState)
    return fetcher('GET', `/billing/portal`, organizationUuid);
}

export interface GetBillingPlansResponse {
    plans: BillingPlan[]
}

export const getBillingPlans = (): Promise<GetBillingPlansResponse> => {
    return fetcher('GET', `/billing/plans`);
}

export interface GetBillingUsageResponse {
    usage: BillingUsage
}

export const getBillingUsage = (): Promise<GetBillingUsageResponse> => {
    const organizationUuid = store.get(organizationUuidState)
    return fetcher('GET', `/billing/usage`, organizationUuid);
}

export interface GetBillingStatusResponse {
    billingStatus: BillingStatus;
    userAction?: string;
    serviceAllowed: boolean;
    code: BillingPlanCode;
}

export const getBillingStatus = (): Promise<GetBillingStatusResponse> => {
    const organizationUuid = store.get(organizationUuidState)
    return fetcher('GET', `/billing/status`, organizationUuid);
}