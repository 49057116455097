import {ProjectTaskStatusBadge} from "../project-view/task/ProjectTaskStatus";
import {DateField} from "../../components/DateField";
import React, {useEffect, useMemo, useRef, useState} from "react";
import {getActiveTeamTasks} from "../../api/projects";
import {useAlerts} from "../../hooks/useAlerts";
import {useNavigate} from "react-router";
import {NamedCard} from "./NamedCard";
import {organizationUuidState} from "../../store";
import {useAtomValue} from "jotai/react";
import {AgTable} from "../../components/AgTable";
import {IGetRowsParams} from "ag-grid-community";
import {LoadingSpinner} from "../Loading";

export default function TeamActiveTasks() {

    const {addError} = useAlerts()
    const navigate = useNavigate();
    const organizationUuid = useAtomValue(organizationUuidState)
    const [loading, setLoading] = useState<boolean>(false);
    const gridApiRef = useRef<any>(null);

    const datasource = useMemo(() => ({
        getRows: (params: IGetRowsParams) => {
            if(!gridApiRef.current?.api) {
                return;
            }
            setLoading(true);
            const pageSize = gridApiRef.current!.api?.paginationGetPageSize();
            const page = Math.floor(params.startRow / pageSize);

            const sortModel = params.sortModel;
            let sortColumn = undefined;
            let sortDir = undefined;
            if (sortModel.length > 0) {
                sortColumn = sortModel[0].colId;
                sortDir = sortModel[0].sort;
            }
            getActiveTeamTasks(pageSize, page, sortColumn, sortDir)
                .then(data => {
                    const rows = data.items.map(item => ({
                        name: item.task,
                        project: item.project,
                        code: item.code,
                        status: item.status,
                        dueDate: new Date(item.dueDate),
                        item: item
                    }));
                    params.successCallback(rows, data.totalElements)
                })
                .catch(error => addError(error))
                .finally(() => setLoading(false))
        },
    }),[addError]);

    useEffect(() => {
        if (gridApiRef.current && gridApiRef.current.api) {
            gridApiRef.current?.api?.refreshInfiniteCache();
        }
    }, [organizationUuid]);

    return (<NamedCard className="mb-3" name={"Your team active tasks"}>
        <div style={{height: '350px', minWidth:'600px'}} className="mt-3">
            <AgTable
                columnDefs={[
                    {
                        field: "name",
                    },
                    {
                        field: "project",
                    },
                    {
                        field: "code",
                    },
                    {
                        field: "status",
                        cellRenderer: (params: any) => <ProjectTaskStatusBadge status={params.value}/>
                    },
                    {
                        field: "dueDate",
                        cellRenderer: (params: any) => <DateField date={params.value}/>
                    }]}
                suppressCellFocus={true}
                datasource={datasource}
                onGridReady={(params: any) => gridApiRef.current = params}
                onStateUpdated={(params) => params.api.sizeColumnsToFit()}
                onGridPreDestroyed={() => gridApiRef.current = null}
                pagination={true}
                loading={loading}
                loadingOverlayComponent={() => <LoadingSpinner/>}
                rowModelType="infinite"
                paginationPageSize={6}
                paginationPageSizeSelector={false}
                cacheBlockSize={6}
                cacheOverflowSize={6}
                onRowClicked={(params: any) => navigate(`/project/${params.data.item.projectUuid}`)}
                maxBlocksInCache={10}
            />
        </div>
    </NamedCard>);
}