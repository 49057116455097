import {Button} from "react-bootstrap";
import {ArrowPathIcon} from "@heroicons/react/16/solid";
import React, {useState} from "react";
import {Variant} from "react-bootstrap/types";

export interface ActionButtonProps {
    handleClick: (done: () => void) => void;
    variant?: Variant;
    title?: string;
    disabled?: boolean;
    icon?: React.ReactElement;
    size?: 'sm' | 'lg';
}

export const ActionButton = (props: ActionButtonProps) => {
    const [saving, setSaving] = useState(false)

    const handleSave = () => {
        setSaving(true);
        props.handleClick(() => {
            const interval = setInterval(() => {
                setSaving(false)
                clearInterval(interval)
            }, 1000)
        })
    }

    return (<Button size={props.size} variant={props.variant ? props.variant : 'outline-danger'}
                    style={{minWidth: '60px'}}
                    disabled={props.disabled}
                    onClick={() => handleSave()}>
        <div className="d-flex flex-row justify-content-center align-items-center">
            <span className="me-1">{props.title ? props.title : 'Save'}</span> {saving &&
            <ArrowPathIcon className="icon-style-sm rotating"/>} {!saving && props.icon}</div>
    </Button>)
}