import {NullableOption, OrganizationMember, Role, SelectOption, Team} from "../../../interfaces";
import XMarkIcon from "@heroicons/react/24/solid/XMarkIcon";
import React, {useEffect, useMemo, useState} from "react";
import {ROLE_OPTIONS, ROLES} from "../../../utils/Constants";
import Select from "react-select";
import {animatedComponents, plainSelect} from "../../../utils/common";
import {removeOrganizationMember, updateOrganizationMember} from "../../../api/organizations";
import {useConfirmModal} from "../../../components/modals/UseConfirmModal";
import CheckIcon from "@heroicons/react/24/solid/CheckIcon";

interface MemberRowProps {
    member: OrganizationMember,
    isCurrentUser: boolean,
    isCurrentUserOwner: boolean,
    teams: Team[],
    reload: () => void
}

export const MemberRow = ({isCurrentUser, isCurrentUserOwner, member, reload, teams}: MemberRowProps) => {
    const {showModal} = useConfirmModal()

    const isMemberOwner = useMemo(() => member.role === 'OWNER', [member.role])
    const teamOptions: NullableOption[] = useMemo(() => teams.filter(team => team.active).map(team => ({
        label: team.name,
        value: team.uuid
    })), [teams])

    const [selectedTeam, setSelectedTeam] = useState<NullableOption>(null)
    const [selectedRole, setSelectedRole] = useState<NullableOption>(null)

    useEffect(() => {
        setSelectedTeam({
            value: member.team?.uuid,
            label: member.team?.name
        })
        setSelectedRole({
            value: member.role,
            label: ROLES[member.role]
        })
    }, [member.role, member.team?.name, member.team?.uuid]);

    const handleDeleteMember = (member: OrganizationMember) => {
        showModal(`Are you sure you want to remove ${member.name}?`,
            () => removeOrganizationMember(member.uuid!)
                .then(_ => reload()));
    }

    const changes = useMemo(() => {
        const changes = [];
        if (selectedTeam?.value !== member.team?.uuid) {
            changes.push(`Team: ${member.team?.name} -> ${selectedTeam?.label}`)
        }
        if (selectedRole?.value !== member.role) {
            changes.push(`Role: ${ROLES[member.role]} -> ${selectedRole?.label}`)
        }
        return changes;
    }, [member.role, member.team?.name, member.team?.uuid, selectedRole?.label, selectedRole?.value, selectedTeam?.label, selectedTeam?.value])

    const hasChanges = useMemo(() => changes.length > 0, [changes.length])

    const handleChange = () => {
        showModal(`Are you sure you want to save these changes to ${member.name}?
        \n${changes.map(change => change + "\n")}`,
            () => updateOrganizationMember({
                userUuid: member.uuid,
                teamUuid: selectedTeam?.value,
                role: selectedRole?.value as Role
            }).then(_ => reload()), 'success');
    }


    return (
        <tr>
            <td className="align-middle text-truncate">
                {member?.name} {isCurrentUser ? (<span className="text-muted">(you)</span>) : ''}
            </td>
            <td className="align-middle truncate-no-width">
                {member?.email}
            </td>
            <td className="align-middle ">
                <Select
                    className="plain-input"
                    value={selectedTeam}
                    options={teamOptions}
                    isSearchable={false}
                    required={true}
                    isDisabled={!isCurrentUserOwner}
                    styles={plainSelect}
                    components={animatedComponents}
                    onChange={(selectedTeam: SelectOption) => setSelectedTeam(selectedTeam)}
                />
            </td>
            <td className="align-middle">
                <Select
                    className="plain-input"
                    value={selectedRole}
                    options={ROLE_OPTIONS}
                    isSearchable={false}
                    required={true}
                    styles={plainSelect}
                    isDisabled={isMemberOwner || !isCurrentUserOwner}
                    components={animatedComponents}
                    onChange={(role: SelectOption) => setSelectedRole(role)}
                />
            </td>
            <td className="align-middle">
                {isCurrentUserOwner && !isMemberOwner &&
                    (<XMarkIcon onClick={() => handleDeleteMember(member)}
                                className="link-danger icon-style pointer me-3"/>)}
                {hasChanges &&
                    (<CheckIcon onClick={() => handleChange()}
                                className="link-success icon-style pointer"/>)}
            </td>
        </tr>
    )
}