import makeAnimated from "react-select/animated";

export const animatedComponents = makeAnimated();

export const plainSelect = {
    control: (provided: any) => ({
        ...provided,
        border: "none",
        background: "transparent",
        boxShadow: "none",
        minHeight: "auto",
        padding: 0,
        width: "auto",
        fontSize: "16px"
    }),
    dropdownIndicator: (provided: any) => ({
        ...provided,
        padding: 4,
        color: "#000",
    }),
    indicatorSeparator: () => ({
        display: "none",
    }),
};