import {Title} from "../../components/Title";
import React, {useEffect, useMemo, useRef, useState} from "react";
import {FullContainer} from "../../components/containers/FullContainer";
import {organizationUuidState} from "../../store";
import {useNavigate} from "react-router";
import {useAlerts} from "../../hooks/useAlerts";
import {Section} from "../../components/Section";
import {Button} from "react-bootstrap";
import {searchProjects} from "../../api/projects";
import {PROJECT_STATUS} from "../../utils/Constants";
import {useAtom} from "jotai/react";
import {IGetRowsParams} from "ag-grid-community";
import {AgTable} from "../../components/AgTable";
import {DateField} from "../../components/DateField";
import CustomSetFilter from "../../components/data-table/SetFilter";
import {mapSkeletonRender} from "../../components/data-table/util";
import {LoadingSpinner} from "../Loading";

export const ProjectListView = () => {

    const [organizationUuid] = useAtom(organizationUuidState)
    const navigate = useNavigate();
    const {addError} = useAlerts();
    const [loading, setLoading] = useState<boolean>(false);
    const gridApiRef = useRef<any>(null);


    const onGridReady = (params: any) => {
        gridApiRef.current = params;
        params.api?.sizeColumnsToFit();
    };

    const datasource = useMemo(() => ({
        getRows: (params: IGetRowsParams) => {
            if (!gridApiRef.current?.api) {
                return;
            }
            setLoading(true);
            const pageSize = gridApiRef.current!.api?.paginationGetPageSize();
            const page = Math.floor(params.startRow / pageSize);

            const sortModel = params.sortModel;
            let sortColumn = undefined;
            let sortDir = undefined;
            if (sortModel.length > 0) {
                sortColumn = sortModel[0].colId;
                sortDir = sortModel[0].sort;
            }
            const filters = params.filterModel
            searchProjects({page, pageSize, filters, sortColumn, sortDir})
                .then(data => {
                    const rows = data.items.map(item => ({
                        name: item.name,
                        code: item.code,
                        status: item.status,
                        owner: item.owner.name,
                        projectedEndDate: new Date(item.projectedEndDate),
                        estimatedEndDate: new Date(item.estimatedEndDate),
                        item: item
                    }));
                    params.successCallback(rows, data.totalElements);
                })
                .catch(error => addError(error))
                .finally(() => setLoading(false))
        },
    }), [addError]);

    useEffect(() => {
        if (gridApiRef.current && gridApiRef.current.api) {
            gridApiRef.current?.api?.refreshInfiniteCache();
        }
    }, [organizationUuid]);

    return (<>
        <FullContainer>
            <Title title="Saved Projects" className="mb-2"/>
            <Section>
                <div style={{height: '540px', width:'1200px'}} className="mt-3 hide-filter-icon pinned-row">
                    <AgTable
                        columnDefs={[
                            {
                                field: "name",
                                width: 300,
                                colSpan: (params: any) => params.data?.addRow ? 6 : 1,
                                cellRenderer: (params: any) => params.data?.addRow ?
                                    <div className="d-flex justify-content-center align-items-center h-100">
                                        <Button size="sm" onClick={() => navigate('/new-project')}>Add New</Button>
                                    </div> :
                                    mapSkeletonRender(loading, params.value),
                                filter: 'agTextColumnFilter',
                                filterParams: {
                                    maxNumConditions: 1,
                                    filterOptions: ['contains']
                                },
                                floatingFilter: true,
                                suppressFloatingFilterButton: true,
                            },
                            {
                                field: "code",
                                width: 160,
                                filter: 'agTextColumnFilter',
                                filterParams: {
                                    maxNumConditions: 1,
                                    filterOptions: ['contains']
                                },
                                floatingFilter: true,
                                suppressFloatingFilterButton: true,
                                cellRenderer: (params: any) => mapSkeletonRender(loading, params.value)
                            },
                            {
                                field: "owner",
                                width: 200,
                                filter: 'agTextColumnFilter',
                                filterParams: {
                                    maxNumConditions: 1,
                                    filterOptions: ['contains']
                                },
                                floatingFilter: true,
                                suppressFloatingFilterButton: true,
                                cellRenderer: (params: any) => mapSkeletonRender(loading, params.value)
                            },
                            {
                                field: "status",
                                width: 176,
                                filter: 'customSetFilter',
                                floatingFilterComponent: 'customSetFilter',
                                filterParams: {
                                    filterValues: Array.from(new Set(Object.keys(PROJECT_STATUS))),
                                    filterLabels: PROJECT_STATUS,
                                },
                                floatingFilter: true,
                                cellRenderer: (params: any) => mapSkeletonRender(loading, PROJECT_STATUS[params.value])
                            },
                            {
                                field: "estimatedEndDate",
                                width: 200,
                                cellRenderer: (params: any) => mapSkeletonRender(loading, <DateField date={params.value}/>)

                            },
                            {
                                field: "projectedEndDate",
                                width: 200,
                                cellRenderer: (params: any) => mapSkeletonRender(loading, <DateField date={params.value}/>)
                            }]}
                        suppressCellFocus={true}
                        datasource={datasource}
                        onGridReady={onGridReady}
                        onGridPreDestroyed={() => gridApiRef.current = null}
                        pagination={true}
                        loading={loading}
                        loadingOverlayComponent={() => <LoadingSpinner/>}
                        pinnedBottomRowData={[{
                            addRow: true
                        }]}
                        gridOptions={{
                            components: {
                                customSetFilter: CustomSetFilter,
                            },
                        }}
                        rowModelType="infinite"
                        paginationPageSizeSelector={false}
                        paginationPageSize={8}
                        cacheBlockSize={8}
                        onRowClicked={(params: any) => navigate(`/project/${params.data.item.uuid}`)}
                        maxBlocksInCache={20}
                    />
                </div>
            </Section>
        </FullContainer>
    </>)
}


