import {Title} from "../../components/Title";
import React, {useEffect, useState} from "react";
import {FullContainer} from "../../components/containers/FullContainer";
import {PageData, Project, SelectOption} from "../../interfaces";
import {organizationUuidState} from "../../store";
import {useNavigate} from "react-router";
import {useAlerts} from "../../hooks/useAlerts";
import {Section} from "../../components/Section";
import {Button, Col, Form, Row, Table} from "react-bootstrap";
import {searchProjects} from "../../api/projects";
import {ProjectListRow} from "./ProjectListRow";
import {PageRow} from "../../components/PageRow";
import Select from "react-select";
import {animatedComponents} from "../../utils/common";
import {PROJECT_STATUS_OPTIONS} from "../../utils/Constants";
import {useAtom} from "jotai/react";

export const ProjectListView = () => {

    const [projectSearchResponse, setProjectSearchResponse] = useState<PageData<Project> | null>(null)
    const [organizationUuid] = useAtom(organizationUuidState)
    const navigate = useNavigate();
    const [currentPage, setCurrentPage] = useState<number>(0)
    const {addError} = useAlerts();
    const [filterStatus, setFilterStatus] = useState<SelectOption | null>(null)
    const [name, setName] = useState<string | null>(null)
    const [owner, setOwner] = useState<string | null>(null)
    const [code, setCode] = useState<string | null>(null)

    useEffect(() => {
        performSearch();
    }, [addError, organizationUuid, currentPage]);

    const performSearch = () => {
        searchProjects({page: currentPage, name, code, owner, status: filterStatus?.value})
            .then(result => setProjectSearchResponse(result))
            .catch(error => addError(error))
    }

    const handleSelect = (project: Project) => {
        navigate(`/project/${project.uuid}`);
    }

    return (<>
        <FullContainer>
            <Title title="Saved Projects" className="mb-5"/>
            <Section title="Filters" collapse={true} className="mb-2 w-75">
                <Form onSubmit={e => {
                    e.preventDefault();
                    performSearch();
                }}>
                    <Row>
                        <Col xl={6} md={12}>
                            <Form.Group as={Row} controlId="name" className="mb-2">
                                <Form.Label column={true}>Project name</Form.Label>
                                <Col>
                                    <Form.Control
                                        value={name || ''}
                                        onChange={e => setName(e.target.value)}
                                        type="text"/>
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row} controlId="code" className="mb-2">
                                <Form.Label column={true}>Code</Form.Label>
                                <Col>
                                    <Form.Control
                                        value={code || ''}
                                        onChange={e => setCode(e.target.value)}
                                        type="text"/>
                                </Col>
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group as={Row} controlId="owner" className="mb-2">
                                <Form.Label column={true}>Owner name</Form.Label>
                                <Col>
                                    <Form.Control
                                        value={owner || ''}
                                        onChange={e => setOwner(e.target.value)}
                                        type="text"/>
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row} controlId="status" className="mb-2">
                                <Form.Label column={true}>Status</Form.Label>
                                <Col>
                                    <Select
                                        value={filterStatus}
                                        options={[...PROJECT_STATUS_OPTIONS, {label: 'All statuses', value: null}]}
                                        components={animatedComponents}
                                        onChange={setFilterStatus}
                                    />
                                </Col>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Button variant="primary" className="mt-2" type="submit">
                                Apply filter
                            </Button>
                        </Col>
                    </Row>
                </Form>
            </Section>
            <Section title="Projects">
                <Table>
                    <thead>
                    <tr>
                        <th>Name</th>
                        <th>Code</th>
                        <th>Status</th>
                        <th>Owner</th>
                        <th>Projected end</th>
                    </tr>
                    </thead>
                    <tbody>
                    {projectSearchResponse?.items.map(project =>
                        <ProjectListRow key={project.uuid} project={project}
                                        onSelect={handleSelect}></ProjectListRow>
                    )}
                    <tr>
                        <td colSpan={6} onClick={() => {
                            navigate('/new-project');
                        }} className="pointer text-center text-muted">Add a new project
                        </td>
                    </tr>
                    </tbody>
                </Table>
                {projectSearchResponse && <PageRow id="project_list"
                                                   {...projectSearchResponse}
                                                   onPageChange={setCurrentPage}
                ></PageRow>}
            </Section>
        </FullContainer>
    </>)
}