import React, {useEffect, useMemo, useState} from "react";
import {Pagination} from "react-bootstrap";

interface PageRowProps {
    id: string;
    hasNext?: boolean;
    hasPrevious?: boolean;
    size: number;
    number: number;
    totalPages: number;
    totalElements: number;
    onPageChange: (page: number) => void;
}

type Page = number | string

export const PageRow = ({
                            id,
                            hasNext,
                            hasPrevious,
                            number,
                            onPageChange,
                            totalPages
                        }: PageRowProps) => {
    const [pages, setPages] = useState<Page[]>([])

    const computedNumber = useMemo(() => number + 1, [number])

    useEffect(() => {
        const computedPages = [];
        for (let i = 1; i <= totalPages; i++) {
            if (i <= 5 || i === totalPages || i === computedNumber) {
                computedPages.push(i);
            } else if (pages[pages.length - 1] !== "...") {
                computedPages.push("...");
            }
        }
        setPages(computedPages)
    }, [computedNumber, setPages, totalPages]);

    const handlePrevious = () => {
        if (hasPrevious) {
            onPageChange(computedNumber - 2);
        }
    };

    const handleNext = () => {
        if (hasNext) {
            onPageChange(computedNumber);
        }
    };

    return (
        <div className="d-flex flex-row">
            <Pagination className="ms-auto me-auto">
                <Pagination.First></Pagination.First>
                <Pagination.Prev onClick={handlePrevious} disabled={!hasPrevious}></Pagination.Prev>
                {pages.map((page, index) =>
                    page === "..." ? (
                        <Pagination.Ellipsis key={`${id}_ellipsis_${index}`}/>
                    ) : (
                        <Pagination.Item
                            key={`${id}_page_${page}`}
                            active={page === computedNumber}
                            onClick={() => onPageChange(Number(page) - 1)}
                        >
                            {page}
                        </Pagination.Item>
                    )
                )}
                <Pagination.Next onClick={handleNext} disabled={!hasNext}></Pagination.Next>
                <Pagination.Last></Pagination.Last>
            </Pagination>
        </div>
    );
}