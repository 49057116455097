/**
 * Formats date string to human friendly date, with dates falling to today and yesterday replaced by text
 */
const formatDate = (dateStr: string) => {
    const dateToCheck = new Date(dateStr);
    return formatDateObj(dateToCheck)
}

const formatDateObj = (dateToCheck: Date) => {
    const today = new Date();
    const yesterday = new Date(today);
    yesterday.setDate(today.getDate() - 1);
    today.setHours(0, 0, 0, 0);
    yesterday.setHours(0, 0, 0, 0);
    const localDateToCheck = new Date(dateToCheck.getTime());
    localDateToCheck.setHours(0, 0, 0, 0);
    if (localDateToCheck.getTime() === today.getTime()) {
        return 'Today';
    } else if (localDateToCheck.getTime() === yesterday.getTime()) {
        return 'Yesterday';
    } else {
        return localDateToCheck.toLocaleDateString(undefined, {
            month: 'short',
            day: 'numeric'
        });
    }
}

const formatDateNumeric = (dateStr: string) => {
    const dateToCheck = new Date(dateStr);
    return formatDateObjNumeric(dateToCheck)
}

const formatDateObjNumeric = (dateToCheck: Date) => {
    const today = new Date();
    const yesterday = new Date(today);
    yesterday.setDate(today.getDate() - 1);
    today.setHours(0, 0, 0, 0);
    yesterday.setHours(0, 0, 0, 0);
    const localDateToCheck = new Date(dateToCheck.getTime());
    localDateToCheck.setHours(0, 0, 0, 0);
    return localDateToCheck.toLocaleDateString(undefined, {
        month: 'numeric',
        day: 'numeric',
        year: 'numeric'
    });
}

const nextWeekday = () => {
    let date = new Date();
    while (!filterWeekend(date)) {
        date.setDate(date.getDate() + 1);
    }
    return date;
}

const filterWeekend = (date: Date) => {
    const day = date.getDay();
    return day !== 0 && day !== 6
};

export {nextWeekday, filterWeekend, formatDate, formatDateObj, formatDateObjNumeric, formatDateNumeric}
