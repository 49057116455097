import {useNavigate} from "react-router";
import {Nav} from "react-bootstrap";
import React from "react";

interface SidebarLinkProps {
    className?: string
    iconComponent: React.ComponentType<{ style?: React.CSSProperties, className?: any }>,
    label: string;
    onClick?: () => void;
    to?: string;
    collapsed?: boolean
}

export const SidebarLink = ({collapsed, onClick, to, className, iconComponent, label}: SidebarLinkProps) => {
    const navigate = useNavigate();
    const IconComponent = iconComponent;

    const doAction = () => {
        if (onClick) {
            onClick();
        } else if (to) {
            navigate(to);
        }
    }

    return (
        <Nav.Link onClick={doAction} className={`pointer link-secondary d-flex align-items-center ${className}`}>
            <div><IconComponent className="link-info" style={{width: '24px'}}/></div>
            <span className={`link-info fade-in-text ${collapsed ? '' : 'visible'}`}>{label}</span>
        </Nav.Link>
    )

}