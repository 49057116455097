import {EditableCell} from "./EditableCell";
import React from "react";

interface MapEditableCellParams {
    values: string[] | ((params: any) => string[]);
    labels: { [string: string]: string }
    field: string;
    editable: (params: any) => boolean;
    width: number;
}

const mapEditableCell = (props: MapEditableCellParams) => {
    return {
        field: props.field as any,
        width: 200,
        cellEditor: "agSelectCellEditor",
        cellEditorParams: (params: any) => {
            if (Array.isArray(props.values)) {
                return {values: props.values};
            } else if (typeof props.values === 'function') {
                return {values: props.values(params)};
            }
            throw new Error('Invalid type for values');
        },
        editable: (params: any) => props.editable(params),
        valueFormatter: (params: any) => {
            return props.labels[params.value]
        },
        cellRenderer: (params: any) => <EditableCell
            disable={!props.editable(params)}
            value={props.labels[params.value]}/>
    }
}

const mapSkeletonRender = (loading: boolean, value: any) => {
    if (loading) {
        return <CustomSkeletonCellRenderer/>
    } else {
        return value;
    }
}

const CustomSkeletonCellRenderer = () => (
    <div className="ag-skeleton-container">
        <div className="ag-skeleton-effect"></div>
    </div>
);

export {mapEditableCell, mapSkeletonRender}