import React, {useEffect} from "react";
import {CenterContainer} from "../components/containers/CenterContainer";
import {useLocation, useNavigate} from "react-router";
import {Button} from "react-bootstrap";
import {getUser} from "../api/users";
import {useAlerts} from "../hooks/useAlerts";

export function UnverifiedView() {

    const location = useLocation();
    const navigate = useNavigate();
    const {addError} = useAlerts();

    useEffect(() => {
        getUser().then(user => {
            if (user?.email) {
                navigate('/home')
            }
        }).catch(error => console.log(error));
    }, [addError, navigate]);

    useEffect(() => {
        const pollInterval = setInterval(() => {
            handleSignIn();
            clearInterval(pollInterval)
        }, 10_000);
        return () => clearInterval(pollInterval);
    }, []);

    const getText = (): string => {
        const provider = new URLSearchParams(location.search).get('provider') || ''
        switch (provider) {
            case 'auth0':
                return 'Please check your inbox and verify your email';
            case '':
                return 'Please verify your email'
            default:
                return `Please verify your email with your social auth provider ${provider} or choose another login option`
        }
    }

    const handleSignIn = () => {
        window.location.href = `${process.env.REACT_APP_API_URL}/login`;
    };

    return (<CenterContainer>
        <div className="d-flex flex-column justify-content-center align-items-center">
            {getText()}
            <Button className="mt-3" variant={"primary"} onClick={handleSignIn}>Sign in</Button>
        </div>

    </CenterContainer>)
}