import {useSetAtom} from "jotai/react";
import {toastMessageState, toastShowState} from "../../store";

export const useToast = () => {

    const setShow = useSetAtom(toastShowState);
    const setMessage = useSetAtom(toastMessageState);

    const setToast = (message: string) => {
        setMessage(message);
        setShow(true)
    }

    return [setToast]
}