function getCookie(name: string) {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) {
        return parts.pop()?.split(';').shift();
    } else {
        return undefined;
    }
}

const fetcher = (method: 'GET' | 'POST' | 'PUT' | 'DELETE', path: string, orgId?: string | null, body?: any) => fetch(`${process.env.REACT_APP_API_URL}${path}`, {
    headers: {
        ...(orgId) && {'X-Org-ID': orgId},
        'Accept': 'application/json',
        ...(['POST', 'PUT', 'DELETE'].includes(method) && {
            'Content-Type': 'application/json',
            'X-XSRF-TOKEN': getCookie('XSRF-TOKEN')
        })
    },
    credentials: 'include',
    method: method,
    ...(body && {body: JSON.stringify(body)})
}).then(async (response) => {
    const contentType = response.headers.get('Content-Type');
    let responseJson;
    if (contentType && contentType.includes('application/json')) {
        responseJson = await response.json() || {message: response.statusText};
    } else {
        responseJson = {message: await response.text() || response.statusText}
    }
    return response.ok ? Promise.resolve(responseJson) : Promise.reject({
        message: responseJson.message,
        status: response.status,
        path: response.url,
        method,
        orgId,
        body
    });
})

export default fetcher