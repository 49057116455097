import {Table} from "react-bootstrap";
import {PageData, TaskInfo} from "../../interfaces";
import {ProjectTaskStatusBadge} from "../project-view/task/ProjectTaskStatus";
import {DateField} from "../../components/DateField";
import React, {useCallback, useEffect, useState} from "react";
import {getActiveTeamTasks} from "../../api/projects";
import {PageRow} from "../../components/PageRow";
import {useAlerts} from "../../hooks/useAlerts";
import {useNavigate} from "react-router";
import {NamedCard} from "./NamedCard";
import {organizationUuidState} from "../../store";
import {useAtom} from "jotai/react";

export default function TeamActiveTasks() {

    const [pageData, setPageData] = useState<PageData<TaskInfo> | null>(null)
    const {addError} = useAlerts()
    const [currentPage, setCurrentPage] = useState<number>(0)
    const navigate = useNavigate();
    const organizationUuid = useAtom(organizationUuidState)[0]

    const delay = (ms: number) => new Promise(resolve => setTimeout(resolve, ms));


    const fetchTeamActiveTasks = useCallback(() => {
        getActiveTeamTasks(5, currentPage)
            .then(data => setPageData(data))
            .then(data => delay(5000))
            .catch(error => addError(error))
    }, [addError, currentPage, organizationUuid]);

    useEffect(() => {
        fetchTeamActiveTasks();
    }, [addError, fetchTeamActiveTasks]);

    return (<NamedCard className="mb-3" name={"Your team active tasks"}>
        <Table>
            <thead>
            <tr>
                <th>Name</th>
                <th>Project</th>
                <th>Code</th>
                <th>Status</th>
                <th>Due date</th>
            </tr>
            </thead>
            <tbody>
            {pageData?.items.map((item: TaskInfo) =>
                <tr key={`team_active_${item.uuid}`} className="pointer link-primary"
                    onClick={() => navigate(`/project/${item.projectUuid}`)}>
                    <td>{item.task}</td>
                    <td>{item.project}</td>
                    <td>{item.projectCode}</td>
                    <td><ProjectTaskStatusBadge status={item.status}/></td>
                    <td><DateField date={item.dueDate}/></td>
                </tr>
            )}
            </tbody>
        </Table>
        <PageRow id="team-active-tasks" {...pageData!} onPageChange={setCurrentPage}/>
    </NamedCard>);
}