import {organizationUuidState} from "../store";
import fetcher from "./fetcher";
import {ProcessTemplate} from "../interfaces";
import {store} from "../index";

export const saveTemplate = (name: string) => {
    const organizationUuid = store.get(organizationUuidState)
    return fetcher('POST', `/template`, organizationUuid, {name});
}

export const saveTemplateTask = ({templateUuid, name}: { templateUuid: string, name: string }) => {
    const organizationUuid = store.get(organizationUuidState)
    return fetcher('POST', `/template/${templateUuid}/task`, organizationUuid, name);
}

export const getTemplates = (): Promise<ProcessTemplate[]> => {
    const organizationUuid = store.get(organizationUuidState)
    return fetcher('GET', '/templates', organizationUuid);
}

export const getTemplate = (templateUuid: string): Promise<ProcessTemplate> => {
    const organizationUuid = store.get(organizationUuidState)
    return fetcher('GET', `/template/${templateUuid}`, organizationUuid);
}