import fetcher from "./fetcher";
import {User} from "../interfaces";
import {organizationUuidState} from "../store";
import {store} from "../index";

export const updateUser = ({name}: { name: string }): Promise<void> => {
    return fetcher('POST', `/user/update`, null, {name});
}

export const resetLoginPassword = (): Promise<void> => {
    return fetcher('POST', `/user/reset-password`);
}

export const getUser = (): Promise<User> => {
    const organizationUuid = store.get(organizationUuidState)
    return fetcher('GET', '/user/info', organizationUuid);
}
