import {Offcanvas} from "react-bootstrap";
import UserIcon from "@heroicons/react/24/solid/UserCircleIcon";
import CogIcon from "@heroicons/react/24/solid/Cog6ToothIcon";
import UsersIcon from "@heroicons/react/24/solid/UsersIcon";
import SignOutIcon from "@heroicons/react/24/solid/ArrowRightEndOnRectangleIcon";
import React from "react";
import {User} from "../../interfaces";
import {SettingsLink} from "./SettingsLink";
import {SidebarDivider} from "./SidebarDivider";
import {CreditCardIcon} from "@heroicons/react/24/solid";
import {useRoles} from "../../hooks/UsePermissions";

interface SettingsMenuProps {
    showSidebar: boolean;
    toggleSidebar: (showSidebar: boolean) => void;
    userImage: any;
    user: User | null;
}

export const SettingsMenu = ({showSidebar, toggleSidebar, userImage, user}: SettingsMenuProps) => {
    const [isOwner] = useRoles()
    const style = {
        width: "300px",
        maxWidth: "100%",
    }
    const logout = () => {
        window.location.href = `${process.env.REACT_APP_API_URL}/logout`
    }
    return (<>
        <Offcanvas className="text-muted" style={style} show={showSidebar} onHide={toggleSidebar} placement="end">
            <Offcanvas.Header closeButton>
                <Offcanvas.Title>
                    <div className="d-flex justify-content-start align-items-center">
                        <div className="me-3">{userImage}</div>
                        <div className="text-muted small">{(`${user?.name}`).trim()}</div>
                    </div>
                </Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
                <div className="d-flex flex-column" style={{height: '100%'}}>
                    <div className="mb-auto">
                        <SettingsLink toggleSettings={toggleSidebar} icon={<UserIcon className="icon-style"/>}
                                      label="Your Profile"
                                      to="/user-profile"></SettingsLink>
                        <SidebarDivider/>
                        <SettingsLink toggleSettings={toggleSidebar} icon={<CogIcon className="icon-style"/>}
                                      label="Organization Settings"
                                      to="/organization-settings"></SettingsLink>
                        <SettingsLink toggleSettings={toggleSidebar} icon={<UsersIcon className="icon-style"/>}
                                      label="Manage Users"
                                      to="/manage-users"></SettingsLink>
                        {isOwner &&
                            <SettingsLink toggleSettings={toggleSidebar} icon={<CreditCardIcon className="icon-style"/>}
                                          label="Subscription"
                                          to="/subscription"></SettingsLink>}
                    </div>

                    <SettingsLink toggleSettings={toggleSidebar} icon={<SignOutIcon className="icon-style"/>}
                                  label="Sign Out"
                                  onClick={logout}></SettingsLink>
                </div>
            </Offcanvas.Body>
        </Offcanvas></>);
}