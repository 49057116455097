import {OverlayTrigger, Tooltip} from "react-bootstrap";
import React from "react";

interface HoverTooltipProps {
    hidden?: boolean;
    children: React.ReactNode;
    text: string;
    delay?: number;
    placement?: import('@restart/ui/usePopper').Placement;
}

export const HoverTooltip = ({hidden, children, text, delay, placement}: HoverTooltipProps) => {

    return (
        <OverlayTrigger
            placement={placement}
            delay={{show: delay || 150, hide: 200}}
            overlay={(props: any) => {
                return !hidden ? <Tooltip id="button-tooltip" {...props} placement={placement}>
                    {text}
                </Tooltip> : <></>
            }}
        >
            <span>{children}</span>
        </OverlayTrigger>
    );
}