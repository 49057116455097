import React, {useEffect, useState} from "react";
import {Col, Collapse, Row} from "react-bootstrap";
import {ChevronDownIcon} from "@heroicons/react/24/solid";

interface SectionProps {
    children: any,
    sidePanel?: any,
    title?: string,
    className?: string
    hide?: boolean;
    collapse?: boolean;
    defaultShowCollapse?: boolean;
}

export const Section = ({children, title, className, sidePanel, hide, collapse, defaultShowCollapse}: SectionProps) => {
    const [collapseState, setCollapseState] = useState<boolean | null>(null)
    useEffect(() => {
        if (collapseState === null) {
            setCollapseState(defaultShowCollapse || false)
        }
    }, [collapseState, defaultShowCollapse]);
    if (hide) {
        return <></>
    }
    return (
        <div className={`mb-4 ${className}`}>
            <div>
                {title && <Row className={collapse ? 'pointer' : ''} onClick={() => setCollapseState(prev => !prev)}>
                    <Col className="d-flex flex-row align-content-center">
                        <h5>{title}</h5>
                        {collapse && <div className="me-2"><ChevronDownIcon
                            className={`icon-style ms-1 chevron ${collapseState ? 'chevron-rotate' : ''}`}
                            style={{transition: 'transform 0.3s ease'}}></ChevronDownIcon></div>}
                    </Col>
                    <Col>{sidePanel}</Col>
                </Row>}
                <div className="mt-3"></div>
                {!collapse && children}
                {collapse && <Collapse in={collapseState || false} children={children}></Collapse>}
            </div>
        </div>
    )
}