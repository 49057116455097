import {useAtom} from "jotai/react";
import {AlertMessage} from "../interfaces";
import {alertsState, organizationUuidState} from "../store";
import React, {useCallback, useEffect} from "react";
import rollbar from "../utils/rollbar";

export const useAlerts = () => {
    const [alerts, setAlerts] = useAtom<AlertMessage[]>(alertsState);
    const organizationUuid = useAtom<string | null>(organizationUuidState)[0];

    useEffect(() => {
        setAlerts([])
    }, [organizationUuid]);

    const addInfo = useCallback((info: any) => {
        setAlerts([{message: info, variant: 'info', id: crypto.randomUUID()}]);
    }, [setAlerts])

    const addError = useCallback((error: any) => {
        if (error?.status === 401) {
            console.info("User not logged in")
        } else if (error?.status === 402) {
            setAlerts([{
                messageHtml: <span>Please <a href='#'
                                             onClick={() => window.location.href = '/subscription'}>upgrade</a> your plan.</span>,
                variant: 'info',
                id: error.id ? error.id : crypto.randomUUID()
            }]);
        } else {
            if (!!process.env.REACT_APP_ROLLBAR_ENABLED) {
                rollbar.error(error.message, error);
            }
            setAlerts([{
                message: error?.error || error.message || 'An error occurred',
                variant: 'danger',
                id: error.id ? error.id : crypto.randomUUID()
            }]);
        }
    }, [setAlerts])

    const removeAlert = useCallback((id: string) => {
        setAlerts((prevAlerts) => prevAlerts.filter((alert) => alert.id !== id));
    }, [setAlerts])

    return {alerts, addError, addInfo, removeAlert};
}