import {ArrowUpRightIcon} from "@heroicons/react/16/solid";
import React, {useCallback} from "react";
import {useNavigate} from "react-router";

interface ArrowLinkProps {
    name: string;
    href?: string;
    to?: string;
    className?: string;
    linkStyle?: 'link-primary' | 'link-secondary' | 'link-danger'
}

export const ArrowLink = (props: ArrowLinkProps) => {

    const navigate = useNavigate();

    const onClick = useCallback(() => {
        if (props.to) {
            navigate(props.to);
        } else if (props.href) {
            window.localStorage.href = props.href;
        }
    }, [navigate, props.href, props.to])

    return (<span
        onClick={onClick}
        className={`text-nowrap ${!props.linkStyle ? 'link-primary' : props.linkStyle} pointer ${props.className ? props.className : ''}`}>{props.name}
        <ArrowUpRightIcon
            className="icon-style-sm"/></span>)
}