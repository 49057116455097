import {FullContainer} from "../../components/containers/FullContainer";
import React, {useEffect, useState} from "react";
import {getBillingPlans, getBillingStatus, GetBillingStatusResponse, getBillingUsage} from "../../api/billing";
import {useAlerts} from "../../hooks/useAlerts";
import {Title} from "../../components/Title";
import {Section} from "../../components/Section";
import {Table} from "react-bootstrap";
import {BillingPlan, BillingUsage} from "../../interfaces";
import {BillingPlanCard} from "./BillingPlanCard";
import {organizationUuidState} from "../../store";
import {useRoles} from "../../hooks/UsePermissions";
import {useAtom} from "jotai/react";

export const SubscriptionView = () => {

    const [billingStatus, setBillingStatus] = useState<GetBillingStatusResponse | null>(null)
    const organizationUuid = useAtom(organizationUuidState)[0]
    const [billingUsage, setBillingUsage] = useState<BillingUsage | null>(null)
    const {addError, addInfo} = useAlerts()
    const [billingPlans, setBillingPlans] = useState<BillingPlan[]>([])
    const [isOwner] = useRoles()

    useEffect(() => {
        getBillingPlans()
            .then(data => setBillingPlans(data.plans))
            .catch(addError)
    }, [addError, organizationUuid])

    useEffect(() => {
        getBillingStatus()
            .then(data => setBillingStatus(data))
            .catch(addError)
    }, [addError, isOwner, organizationUuid])

    useEffect(() => {
        getBillingUsage()
            .then(data => setBillingUsage(data.usage))
            .catch(error => {
            })
    }, [addError, isOwner, organizationUuid])

    useEffect(() => {
        if (!isOwner && billingStatus?.billingStatus === 'NONE') {
            addInfo("No valid billing plan found. Contact organization administrator to subscribe.")
        }
    }, [addError, addInfo, billingStatus, isOwner, organizationUuid])

    return (
        <FullContainer>
            <Title
                title={billingStatus?.billingStatus === 'NONE' ? 'Start using ProcessPlot by choosing a plan' : 'Subscription'}
                className={"mb-5"}></Title>
            <Section title="Available Plans">
                <div className="d-flex flex-row align-items-center">
                    {billingPlans.map((billingPlan: BillingPlan) => (
                        <BillingPlanCard key={billingPlan.code}
                                         current={!!billingStatus?.serviceAllowed && (billingStatus?.code === billingPlan.code)}
                                         viewOnly={!isOwner}
                                         plan={billingPlan}/>
                    ))}
                </div>
                <div className="small mt-5">
                    <span>By subscribing to the services of ProcessPlot you agree to our <a className="link-primary"
                                                                                            href="https://processplot.com/terms-of-service/"
                                                                                            target="_blank">Terms of Service</a>.<br/></span>
                    <span>ProcessPlot has the right to limit unlimited usage plan if any kind of system abuse is detected.</span>
                </div>
            </Section>
            {billingUsage &&
                <Section title="Plan Usage" className="mt-3">
                    <Table className="w-50">
                        <thead>
                        <tr>
                            <th>Resource</th>
                            <th>Used</th>
                            <th>Plan</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td>Users</td>
                            <td>{billingUsage?.actualUsers}</td>
                            <td>{billingUsage?.allowedUsers === -1 ? 'Unlimited' : billingUsage.allowedUsers}</td>
                        </tr>
                        <tr>
                            <td>Processes</td>
                            <td>{billingUsage?.actualProcesses}</td>
                            <td>{billingUsage?.allowedProcesses === -1 ? 'Unlimited' : billingUsage.allowedProcesses}</td>
                        </tr>
                        <tr>
                            <td>Projects</td>
                            <td>{billingUsage?.actualProjects}</td>
                            <td>{billingUsage?.allowedProjects === -1 ? 'Unlimited' : billingUsage.allowedProjects}</td>
                        </tr>
                        </tbody>
                    </Table>
                </Section>}
        </FullContainer>
    );
}