import {Button, Row} from "react-bootstrap";
import React, {useEffect, useState} from "react";
import {UserOrganization} from "../../interfaces";
import {deleteOrganization, leaveOrganization} from "../../api/organizations";

import {organizationUuidState, userState} from "../../store";
import {useNavigate} from "react-router";
import {useAlerts} from "../../hooks/useAlerts";
import {getUser} from "../../api/users";
import {useConfirmModal} from "../../components/modals/UseConfirmModal";
import {useAtom} from "jotai/react";
import {AgTable} from "../../components/AgTable";
import {ROLES} from "../../utils/Constants";
import {UserOrganizationActions} from "./UserOrganizationActions";
import {Section} from "../../components/Section";

export const UserOrganizationList = () => {
    const [user, setUser] = useAtom(userState);
    const [organizationUuid, setOrganizationUuid] = useAtom(organizationUuidState);
    const navigate = useNavigate();
    const {addError} = useAlerts();
    const {showModal} = useConfirmModal()
    const [rows, setRows] = useState<any[]>([])

    const handleLeave = (organization: UserOrganization) => {
        showModal(`Are you sure you want to leave ${organization.name}?`, () => leaveOrganization(organization.uuid)
            .catch(error => addError(error))
            .then(_ => handlePostRemove()));
    }

    const handleDelete = (organization: UserOrganization) => {
        showModal(`Are you sure you want to delete ${organization.name}?`, () => deleteOrganization(organization.uuid)
            .catch(error => addError(error))
            .then(_ => handlePostRemove()));
    }

    const handlePostRemove = async () => {
        return getUser()
            .then(data => {
                setUser(data)
                if (data.organizations.length === 0) {
                    throw Error("No organizations left");
                }
                const requiresOrgChange = !data.organizations?.find(org => org.uuid === organizationUuid)
                if (requiresOrgChange) {
                    setOrganizationUuid(data.organizations[0].uuid)
                }
            }).catch(error => {
                setUser(null);
                navigate("/landing")
                addError(error)
            })
    }

    useEffect(() => {
        const organizations = user?.organizations.map(organization => ({
            name: organization.name + (organizationUuid === organization.uuid ? ' (you)' : ''),
            role: organization?.roles.map(role => ROLES[role]) || '',
            organization: organization
        })) || [];
        setRows([...organizations])
    }, [organizationUuid, setOrganizationUuid, user?.organizations]);

    return (
        <Section title="My organizations" className="mt-4">
            <div className="mt-2 pinned-row" style={{width: '600px'}}>
                <AgTable
                    columnDefs={[
                        {
                            field: "name",
                            width: 250,
                            colSpan: (params: any) => params.data.addRow ? 3 : 1,
                            cellRenderer: (params: any) => params.data.addRow ?
                                <div className="d-flex justify-content-center align-items-center h-100">
                                    <Button size="sm" onClick={() => navigate('/setup-organization')}>Add New</Button></div> :
                                params.value
                        },
                        {
                            field: "role",
                            width: 150,
                        },
                        {
                            headerName: 'Actions',
                            field: "organization",
                            cellRenderer: (params: any) => <UserOrganizationActions
                                handleDelete={handleDelete}
                                organization={params.data.organization}
                                handleLeave={handleLeave}/>,
                            width: 200,
                        }]}
                    rowData={rows}
                    domLayout='autoHeight'
                    pinnedBottomRowData={[{
                        addRow: true,
                    }]}
                    rowClass="row-hover"
                    suppressCellFocus={true}
                />
            </div>
        </Section>
    )
}