import {HoverTooltip} from "./HoverTooltip";
import {formatDate, formatDateNumeric} from "../utils/DateUtils";
import React from "react";

interface DateFieldProps {
    date: string;
    suffix?: string;
    className?: string;
}

export const DateField = (props: DateFieldProps) => {

    return <HoverTooltip text={formatDateNumeric(props.date)}>
        <span className={props.className}>{formatDate(props.date)}{props.suffix && ` ${props.suffix}`}</span>
    </HoverTooltip>
}