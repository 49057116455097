import {ClientSideRowModelModule} from '@ag-grid-community/client-side-row-model';
import {ModuleRegistry} from '@ag-grid-community/core';
import {AgGridReact} from '@ag-grid-community/react';
import 'ag-grid-community/styles/ag-grid.css'; // Import base ag-grid styles
import 'ag-grid-community/styles/ag-theme-quartz.css'; // Import Quartz theme styles
import React from "react";
import type {AgGridReactProps} from "@ag-grid-community/react/dist/types/src/shared/interfaces";
import {InfiniteRowModelModule} from "@ag-grid-community/infinite-row-model";

// Register the module
ModuleRegistry.registerModules([ClientSideRowModelModule, InfiniteRowModelModule]);

export function AgTable<T>(props: AgGridReactProps<T>) {

    return (
        <div
            className="ag-theme-quartz"
            style={{height: '100%'}}
        >
            <AgGridReact {...props}/>
        </div>
    );
}