import {Badge} from "react-bootstrap";
import React from "react";
import {useLocation} from "react-router";

export default function WizardProgress() {
    const location = useLocation();

    const getCurrentStep = (location: string): number => {
        if (location.startsWith('/wizard/tasks')) {
            return 1;
        } else if (location.startsWith('/wizard/teams-and-tools')) {
            return 2;
        } else if (location.startsWith('/wizard/arrange')) {
            return 3;
        } else {
            return 1;
        }
    }

    const currentStep = getCurrentStep(location.pathname)
    const getButtonVariant = (step: number) => {
        return currentStep >= step ? 'primary' : 'secondary'
    }

    return (
        <div>
            <Badge className="me-1" bg={getButtonVariant(1)}>1</Badge>
            <Badge className="me-1" bg={getButtonVariant(2)}>2</Badge>
            <Badge className="me-1" bg={getButtonVariant(3)}>3</Badge>
        </div>
    );
}