import {useSortable} from "@dnd-kit/sortable";
import {CSS} from "@dnd-kit/utilities";
import React from "react";

interface SortableElementProps {
    id: string,
    children?: any,
    disabled?: boolean,
};
export const SortableElement = (props: SortableElementProps) => {
    const {
        attributes,
        listeners,
        setNodeRef,
        transform,
        transition,
    } = useSortable({id: props.id, disabled: props.disabled});

    const itemStyle = {
        transform: CSS.Translate.toString(transform),
        transition,
        alignItems: "center",
        borderRadius: 5,
        cursor: "grab",
    };

    return (
        <div id={props.id} ref={setNodeRef} {...attributes} {...listeners} style={itemStyle}>
            {props.children}
        </div>
    );
};