import React, {useEffect} from "react";
import {organizationUuidState, wizardProcessState} from "../../../store";
import WizardItemSelect from "../WizardItemSelect";
import WizardFrame from "../WizardFrame";
import {useNavigate, useParams} from "react-router";
import {useRoles} from "../../../hooks/UsePermissions";
import {Section} from "../../../components/Section";
import {useAlerts} from "../../../hooks/useAlerts";
import {getWizardProcess, updateWizardProcess} from "../../../api/wizard";
import {useAtom} from "jotai/react";
import {FullContainer} from "../../../components/containers/FullContainer";

export default function WizardTeamsAndTools() {
    const [wizardProcess, setWizardProcess] = useAtom(wizardProcessState)
    const organizationUuid = useAtom(organizationUuidState)[0]
    const navigate = useNavigate()
    const [isOwner] = useRoles()
    const params = useParams();
    const {addError} = useAlerts();

    useEffect(() => {
        if (params.uuid) {
            getWizardProcess(params.uuid)
                .then(data => setWizardProcess(data))
                .catch(error => addError(error))
        }
    }, [addError, setWizardProcess, params.uuid, organizationUuid]);

    const handleNext = () => {
        updateWizardProcess(wizardProcess)
            .then(_ => navigate(`/wizard/arrange/${wizardProcess.uuid}`))
            .catch(error => addError(error))
    }

    const handlePrev = () => {
        navigate(`/wizard/tasks/${wizardProcess.uuid}`)
    }

    const nextEnabled = wizardProcess.teams.some(team => team.selected) && wizardProcess.tools.some(tool => tool.selected)

    return (
        <FullContainer width={50}>
            <WizardFrame
                title="Let's learn more about your process"
                next={handleNext}
                prev={handlePrev}
                nextDisabled={!nextEnabled}
                nextDisabledTooltip={"Select at least one team and tool"}
            >
                <Section title="Which teams are involved in the process?">
                    <WizardItemSelect itemType="teams" hideAdd={!isOwner}></WizardItemSelect>
                </Section>

                <Section title="Which tools are used in the process?">
                    <WizardItemSelect itemType="tools"></WizardItemSelect>
                </Section>
            </WizardFrame>
        </FullContainer>)
}