import React, {useState} from "react";
import {Button, Col, Form, FormControl, Row, Table} from "react-bootstrap";
import {setupOrganization} from "../../api/organizations";
import {useNavigate} from "react-router";
import {organizationUuidState, userState} from "../../store";
import XMarkIcon from "@heroicons/react/24/solid/XMarkIcon";
import CreatableSelect from "react-select/creatable";
import Select from "react-select";
import makeAnimated from 'react-select/animated';
import {Invitation, NullableOption, Role, SelectOption} from "../../interfaces";
import {ROLE_OPTIONS, TEAM_OPTIONS, TOOL_OPTIONS} from "../../utils/Constants";
import {FullContainer} from "../../components/containers/FullContainer";
import {useAlerts} from "../../hooks/useAlerts";
import {Section} from "../../components/Section";
import {Title} from "../../components/Title";
import {HoverTooltip} from "../../components/HoverTooltip";
import {getUser} from "../../api/users";
import {plainSelect} from "../../utils/common";
import {useAtom, useSetAtom} from "jotai/react";


const animatedComponents = makeAnimated();
const customComponents = {
    ...animatedComponents,
    DropdownIndicator: () => null,
    IndicatorSeparator: () => null,
};

export function SetupOrganizationView() {

    const [organizationName, setOrganizationName] = useState('')
    const [organizationEmail, setOrganizationEmail] = useState('')
    const [invites, setInvites] = useState<Invitation[]>([]);
    const [teams, setTeams] = useState<NullableOption[]>([]);
    const [tools, setTools] = useState<NullableOption[]>([]);
    const navigate = useNavigate()
    const [user, setUser] = useAtom(userState)
    const setOrganizationUuid = useSetAtom(organizationUuidState)
    const firstOrganization = !user?.organizations?.length
    const [userTeam, setUserTeam] = useState<NullableOption>(null)
    const {addError} = useAlerts();

    const handleAddInvite = () => {
        setInvites([...invites, {email: '', team: null, role: {value: 'MEMBER', label: 'User'}}]);
    };

    const handleRemoveInvite = (index: number) => {
        const newInvites = invites.filter((_, i) => i !== index);
        setInvites(newInvites);
    };

    const handleInviteChange = (index: number, invite: Partial<{
        email: string,
        team: SelectOption,
        role: SelectOption
    }>) => {
        const newInvites = [...invites];
        newInvites[index] = {...newInvites[index], ...invite}
        setInvites(newInvites);
    }

    const onFormSubmit = (event: React.MouseEvent<HTMLButtonElement> | React.FormEvent) => {
        event.stopPropagation();
        event.preventDefault();
        const request = {
            organizationName,
            userTeam: userTeam?.value!,
            teams: teams.filter(team => !!team).map(team => team!.value),
            tools: tools.filter(tool => !!tool).map(tool => tool!.value),
            email: organizationEmail,
            invitations: invites.map(invite => ({
                email: invite.email,
                role: invite.role.value as Role,
                team: invite.team?.value!
            }))
        }
        setupOrganization(request)
            .then(resp => resp.uuid)
            .then(organizationUuid => setOrganizationUuid(organizationUuid))
            .then(_ => getUser())
            .then(user => setUser(user))
            .then(_ => navigate("/home"))
            .catch(error => addError(error))
    }

    const handleTeamsChange = (newTeams: NullableOption[]) => {
        setTeams(newTeams)
        setUserTeam(prev => newTeams.includes(prev) ? prev : null)
        setInvites(prev => prev.map(invite => !newTeams.includes(invite.team) ? {...invite, team: null} : invite))
    };

    return (
        <FullContainer>
            <Title title={firstOrganization ? 'Welcome to ProcessPlot!' : 'Set up an organization'}
                   subtitle={firstOrganization ? 'To continue to ProcessPlot set up your organisation' : ''}
                   className=""></Title>
            <Form className="mt-5" onSubmit={onFormSubmit}>
                <Section title="Details">
                    <Row>
                        <Col md={2} className="d-flex flex-row align-items-center">
                            Organization name
                        </Col>
                        <Col md={6}>
                            <FormControl
                                value={organizationName}
                                type="text"
                                maxLength={80}
                                minLength={5}
                                required={true}
                                onChange={e => setOrganizationName(e.currentTarget.value)}
                            />
                        </Col>
                    </Row>
                    <Row className="mt-3">
                        <Col md={2} className="d-flex flex-row align-items-center">
                            Billing email
                        </Col>
                        <Col md={6}>
                            <FormControl
                                value={organizationEmail}
                                type="email"
                                required={true}
                                onChange={e => setOrganizationEmail(e.currentTarget.value)}
                            />
                        </Col>
                    </Row>
                    <Row className="mt-3">
                        <Col md={2} className="d-flex flex-row align-items-center">
                            Teams
                        </Col>
                        <Col md={6}>
                            <HoverTooltip text="Type and press enter to add your own team" placement="right">
                                <CreatableSelect
                                    onKeyDown={e => e.key === "Enter" && e.stopPropagation()}
                                    className="w-100"
                                    noOptionsMessage={() => "Type to add more"}
                                    options={TEAM_OPTIONS}
                                    components={customComponents}
                                    closeMenuOnSelect={false}
                                    isMulti={true}
                                    onChange={handleTeamsChange}
                                    value={teams}
                                />
                            </HoverTooltip>
                        </Col>
                    </Row>
                    <Row className="mt-3">
                        <Col md={2} className="d-flex flex-row align-items-center">
                            Tools
                        </Col>
                        <Col md={6}>
                            <HoverTooltip text="Type and press enter to add your own tool" placement="right">
                                <CreatableSelect
                                    onKeyDown={e => e.key === "Enter" && e.stopPropagation()}
                                    className="w-100"
                                    noOptionsMessage={() => "Type to add more"}
                                    options={TOOL_OPTIONS}
                                    components={customComponents}
                                    closeMenuOnSelect={false}
                                    isMulti={true}
                                    onChange={setTools}
                                    value={tools}
                                />
                            </HoverTooltip>
                        </Col>
                    </Row>
                </Section>

                <Section title="Users" className="mt-5">
                    <Row className="mt-3">
                        <Col md={12}>
                            <Table className="fixed-table">
                                <thead>
                                <tr>
                                    <th>Email</th>
                                    <th>Team</th>
                                    <th>Permissions</th>
                                    <th className="align-middle">Actions</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr>
                                    <td className="align-middle">
                                        {user?.email} <span className="text-muted">(you)</span>
                                    </td>
                                    <td className="align-middle">
                                        <Select
                                            className="plain-input"
                                            value={userTeam}
                                            options={teams}
                                            styles={plainSelect}
                                            required={true}
                                            components={animatedComponents}
                                            onChange={setUserTeam}
                                        />
                                    </td>
                                    <td className="align-middle">
                                        <Select
                                            className="plain-input"
                                            value={{value: 'OWNER', label: 'Admin'}}
                                            isDisabled={true}
                                            styles={plainSelect}
                                            components={animatedComponents}
                                        />
                                    </td>
                                    <td className="align-middle">

                                    </td>
                                </tr>
                                {invites.map((invite, index) => (
                                    <tr key={index}>
                                        <td className="align-middle">
                                            <Form.Control
                                                plaintext
                                                data-form-type="other"
                                                data-lpignore="true"
                                                className="plain-input"
                                                type="email"
                                                required={true}
                                                autoComplete="false"
                                                value={invite.email}
                                                onChange={(event) => handleInviteChange(index, {email: event.currentTarget.value})}
                                                placeholder={`example@example.com`}
                                            />
                                        </td>
                                        <td className="align-middle">
                                            <Select
                                                className="plain-input"
                                                value={invite.team}
                                                options={teams}
                                                required={true}
                                                styles={plainSelect}
                                                components={animatedComponents}
                                                onChange={(team: SelectOption) => handleInviteChange(index, {team})}
                                            />
                                        </td>
                                        <td>
                                            <Select
                                                className="plain-input"
                                                value={invite.role}
                                                options={ROLE_OPTIONS}
                                                styles={plainSelect}
                                                required={true}
                                                components={animatedComponents}
                                                onChange={(role: SelectOption) => handleInviteChange(index, {role})}
                                            />
                                        </td>
                                        <td className="align-middle">
                                            <div className="d-flex align-items-center link-danger"
                                                 style={{cursor: "pointer"}}>
                                                <XMarkIcon className="icon-style"
                                                           onClick={() => handleRemoveInvite(index)}/>
                                            </div>
                                        </td>
                                    </tr>
                                ))}
                                <tr onClick={handleAddInvite}>
                                    <td colSpan={4}>
                                        <div className="text-muted pointer d-flex flex-row justify-content-center">
                                            <span>Click to add people to invite</span>
                                        </div>
                                    </td>
                                </tr>
                                </tbody>
                            </Table>
                        </Col></Row>
                </Section>
                <Row className="mt-5">
                    <Col md={12} className="d-flex flex-row justify-content-center">
                        <Button
                            type="submit"
                            onSubmit={onFormSubmit}
                        >Create organization</Button>
                    </Col>
                </Row>
            </Form>
        </FullContainer>
    );
}