import React from "react";

export const UsersIcon = (props: any) => (
    <svg width="26" height="26" {...props} viewBox="0 0 26 26" fill="none"
         xmlns="http://www.w3.org/2000/svg">
        <path
            d="M25 13C25 16.8526 23.2316 20.2632 20.3895 22.4105C18.3684 23.9895 15.7789 25 13 25C10.2211 25 7.63157 24.0526 5.61052 22.4105C2.83157 20.2 1 16.7895 1 13C1 6.36842 6.36842 1 13 1C19.6316 1 25 6.36842 25 13Z"
            stroke="black" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path
            d="M13 14.579C10.7895 14.579 9.02106 12.8105 9.02106 10.6V9.02106C9.02106 6.81054 10.7895 5.04211 13 5.04211C15.2105 5.04211 16.9789 6.81054 16.9789 9.02106V10.6C16.9789 12.8105 15.2105 14.579 13 14.579Z"
            stroke="black" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path
            d="M20.3895 22.4105C18.3684 23.9895 15.779 25 13 25C10.2211 25 7.63159 24.0526 5.61053 22.4105L6.17897 20.0105C6.93687 16.7895 9.77897 14.5789 13.0632 14.5789C16.3474 14.5789 19.1895 16.8526 19.9474 20.0105L20.3895 22.4105Z"
            stroke="black" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
)