import React, {useEffect, useState} from "react";
import {organizationState} from "../../store";
import {ActiveUsersList} from "./active/ActiveUsersList";
import {InvitedUsersList} from "./pending/InvitedUsersList";
import {OrganizationMember, Team} from "../../interfaces";
import {Title} from "../../components/Title";
import {useRoles} from "../../hooks/UsePermissions";
import {FullContainer} from "../../components/containers/FullContainer";
import {useAlerts} from "../../hooks/useAlerts";
import {getOrganizationMembers} from "../../api/organizations";
import {useAtom} from "jotai/react";
import {getTeams} from "../../api/teams";

export function ManageUsersView() {
    const organization = useAtom(organizationState)[0];
    const [isOwner] = useRoles()
    const [members, setMembers] = useState<OrganizationMember[]>([])
    const {addError} = useAlerts();
    const [teams, setTeams] = useState<Team[]>([])

    useEffect(() => {
        getTeams()
            .then(data => setTeams(data))
            .catch(addError);

    }, [addError]);

    useEffect(() => {
        getOrganizationMembers()
            .then(data => setMembers(data))
            .catch(error => addError(error))
    }, [addError, organization?.uuid]);


    return <FullContainer>
        <Title title="User management" subtitle={organization?.name} className="mb-5"/>
        <ActiveUsersList teams={teams} organizationUuid={organization?.uuid} members={members} setMembers={setMembers}
                         isOwner={isOwner}/>
        <InvitedUsersList teams={teams} organizationUuid={organization?.uuid} members={members} isOwner={isOwner}/>
    </FullContainer>
}