import React from "react";
import {Col, Row} from "react-bootstrap";
import {Title} from "../../components/Title";
import {FullContainer} from "../../components/containers/FullContainer";
import {Section} from "../../components/Section";
import {GetStarted} from "./GetStarted";
import TeamUpcomingTasks from "./TeamUpcomingTasks";
import TeamActiveTasks from "./TeamActiveTasks";

export default function HomeView() {
    return (
        <FullContainer className={"h-75"}>
            <Title title="Home" className="mb-5"/>
            <Section title="">
                <Row>
                    <Col>
                        <TeamActiveTasks/>
                        <TeamUpcomingTasks/>
                    </Col>
                    <Col>
                        <GetStarted className="mb-3"/>
                    </Col>
                </Row>
            </Section>
        </FullContainer>
    );
}