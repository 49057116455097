import {ArrowUpRightIcon} from "@heroicons/react/16/solid";
import React from "react";
import {useNavigate} from "react-router";
import {NamedCard} from "./NamedCard";

interface GetStartedProps {
    className?: string;
}

export const GetStarted = (props: GetStartedProps) => {
    const navigate = useNavigate()

    return (
        <NamedCard name={"Get started"} className={props.className}>
            <ul>
                <li className="link-primary pointer"
                    onClick={() => navigate('/organization-settings')}>Set up teams and tools <ArrowUpRightIcon
                    className="icon-style-sm"/></li>
                <li className="link-primary pointer"
                    onClick={() => navigate('/manage-users')}>Invite more people to your organization <ArrowUpRightIcon
                    className="icon-style-sm"/></li>
                <li className="link-primary pointer"
                    onClick={() => navigate('/wizard/tasks')}>Define a process <ArrowUpRightIcon
                    className="icon-style-sm"/></li>
                <li className="link-primary pointer"
                    onClick={() => navigate('/new-project')}>Create a new
                    project <ArrowUpRightIcon
                        className="icon-style-sm"/>
                </li>
                <li className="link-primary pointer"
                    onClick={() => navigate('/projects')}>Open a saved project <ArrowUpRightIcon
                    className="icon-style-sm"/>
                </li>
            </ul>
        </NamedCard>
    )
}