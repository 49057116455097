import {createBrowserRouter} from "react-router-dom";
import ErrorView from "./routes/ErrorView";
import HomeView from "./routes/home-view/HomeView";
import React from "react";
import UploadView from "./routes/UploadView";
import WizardTasksView from "./routes/wizard/tasks/WizardTasks";
import WizardTeamsAndToolsView from "./routes/wizard/teams-and-tools/WizardTeamsAndTools";
import WizardArrangeView from "./routes/wizard/arrange/WizardArrange";
import LandingPageView from "./routes/LandingView";
import NavigationGuard from "./components/NavigationGuard";
import ProcessListView from "./routes/process-list/ProcessListView";
import {SetupOrganizationView} from "./routes/setup-organization/SetupOrganizationView";
import {PendingInvitationsView} from "./routes/pending-invitations/PendingInvitationsView";
import {UserInvitationView} from "./routes/UserInvitationView";
import {UserProfileView} from "./routes/profile/UserProfileView";
import {OrganizationSettingsView} from "./routes/organization-settings/OrganizationSettingsView";
import {UnverifiedView} from "./routes/UnverifiedView";
import {ManageUsersView} from "./routes/manage-users/ManageUsersView";
import {ProjectListView} from "./routes/project-list/ProjectListView";
import App from "./App";
import {SubscriptionView} from "./routes/subscription/SubscriptionView";
import AnalyticsReportView from "./routes/analytics/AnalyticsReportView";
import {TeamReportView} from "./routes/analytics/TeamReportView";
import {BillingUsageView} from "./routes/billing-usage/BillingUsageView";

const LazyProcessView = React.lazy(() => import(/* webpackPrefetch: true */ './routes/process-view/ProcessView'));
const LazyProjectView = React.lazy(() => import(/* webpackPrefetch: true */ './routes/project-view/ProjectView'));
const LazyNewProjectView = React.lazy(() => import(/* webpackPrefetch: true */ './routes/NewProjectView'));
const LazyCheckoutView = React.lazy(() => import('./routes/checkout/CheckoutView'));
const LazyAnalyticsReportView = React.lazy(() => import(/* webpackPrefetch: true */ './routes/analytics/AnalyticsReportView'));
const LazyProcessReportView = React.lazy(() => import(/* webpackPrefetch: true */ './routes/analytics/ProcessReportView'));

export const router = createBrowserRouter([
    {
        path: "/",
        element: <App/>,
        children: [
            {
                errorElement: <ErrorView/>,
                children: [
                    {
                        path: "landing",
                        element: <LandingPageView/>,
                    },
                    {
                        path: "home",
                        element: <NavigationGuard disablePaywall={false}><HomeView/></NavigationGuard>,
                    },
                    {
                        path: "wizard/tasks",
                        element: <NavigationGuard><WizardTasksView/></NavigationGuard>,
                    },
                    {
                        path: "wizard/tasks/:uuid",
                        element: <NavigationGuard><WizardTasksView/></NavigationGuard>,
                    },
                    {
                        path: "wizard/teams-and-tools/:uuid",
                        element: <NavigationGuard><WizardTeamsAndToolsView/></NavigationGuard>,
                    },
                    {
                        path: "wizard/arrange/:uuid",
                        element: <NavigationGuard><WizardArrangeView/></NavigationGuard>,
                    },
                    {
                        path: "upload",
                        element: <NavigationGuard><UploadView/></NavigationGuard>,
                    },
                    {
                        path: "process/:uuid",
                        element: <NavigationGuard><LazyProcessView/></NavigationGuard>,
                    },
                    {
                        path: "process/list",
                        element: <NavigationGuard><ProcessListView/></NavigationGuard>,
                    },
                    {
                        path: "setup-organization",
                        element: <NavigationGuard disablePaywall={true}><SetupOrganizationView/></NavigationGuard>,
                    },
                    {
                        path: "invitation/:uuid",
                        element: <UserInvitationView/>,
                    },
                    {
                        path: "pending-invitations",
                        element: <NavigationGuard disablePaywall={true}><PendingInvitationsView/></NavigationGuard>,
                    },
                    {
                        path: "user-profile",
                        element: <NavigationGuard disablePaywall={true}><UserProfileView/></NavigationGuard>,
                    },
                    {
                        path: "organization-settings",
                        element: <NavigationGuard disablePaywall={true}><OrganizationSettingsView/></NavigationGuard>,
                    },
                    {
                        path: "unverified",
                        element: <UnverifiedView/>,
                    },
                    {
                        path: "manage-users",
                        element: <NavigationGuard disablePaywall={true}><ManageUsersView/></NavigationGuard>,
                    },
                    {
                        path: "analytics",
                        element: <NavigationGuard><AnalyticsReportView/></NavigationGuard>,
                    },
                    {
                        path: "projects",
                        element: <NavigationGuard><ProjectListView/></NavigationGuard>,
                    },
                    {
                        path: "project/:uuid",
                        element: <NavigationGuard><LazyProjectView/></NavigationGuard>,
                    },
                    {
                        path: "new-project",
                        element: <NavigationGuard><LazyNewProjectView/></NavigationGuard>,
                    },
                    {
                        path: "checkout",
                        element: <NavigationGuard roles={["OWNER"]}
                                                  disablePaywall={true}><LazyCheckoutView/></NavigationGuard>,
                    },
                    {
                        path: "subscription",
                        element: <NavigationGuard disablePaywall={true}><SubscriptionView/></NavigationGuard>,
                    },
                    {
                        path: "billing-usage",
                        element: <NavigationGuard><BillingUsageView/></NavigationGuard>,
                    },
                    {
                        path: "analytics",
                        element: <NavigationGuard><LazyAnalyticsReportView/></NavigationGuard>,
                    },
                    {
                        path: "analytics/process/:uuid",
                        element: <NavigationGuard><LazyProcessReportView/></NavigationGuard>,
                    },
                    {
                        path: "analytics/team/:uuid",
                        element: <NavigationGuard><TeamReportView/></NavigationGuard>,
                    },
                ],
            },
            {path: "*", element: <ErrorView/>},
        ]
    },
]);
