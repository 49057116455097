import {Col, FormControl, InputGroup, Row} from "react-bootstrap";
import React, {useEffect, useRef, useState} from "react";
import {getUser, resetLoginPassword, updateUser} from "../../api/users";

import {userState} from "../../store";
import EnvelopeIcon from "@heroicons/react/24/solid/EnvelopeIcon";
import {useAlerts} from "../../hooks/useAlerts";
import {HoverTooltip} from "../../components/HoverTooltip";
import {ActionButton} from "../../components/ActionButton";
import {useAtom} from "jotai/react";

export const EditUserProfile = () => {
    const [user, setUser] = useAtom(userState)
    const [userName, setUserName] = useState<string>('')
    const inputRef = useRef<HTMLInputElement>(null);
    const {addError} = useAlerts()

    useEffect(() => {
        setUserName(user?.name || '')
    }, [user?.name]);

    const onNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.currentTarget.value;
        setUserName(value)
    }

    const hasChanges = user?.name !== userName;

    const handleResetPassword = (done: Function) => {
        resetLoginPassword()
            .then(() => done())
            .catch(error => addError(error))
    }

    const handleSaveName = (done: Function) => {
        if (!inputRef.current?.checkValidity()) {
            inputRef.current?.reportValidity();
            return;
        }
        updateUser({name: userName})
            .then(_ => getUser())
            .then(user => setUser(user))
            .then(() => done())
            .catch(error => addError(error))
    }

    return (
        <Row>
            <Col md={3}>
                <InputGroup>
                    <FormControl type="text"
                                 name="name"
                                 data-form-type="other"
                                 data-lpignore="true"
                                 value={userName}
                                 ref={inputRef}
                                 maxLength={50}
                                 minLength={2}
                                 required={true}
                                 placeholder="Full name"
                                 onKeyDown={e => (e.key === 'Enter') && handleSaveName(() => {
                                 })}
                                 onChange={onNameChange}></FormControl>
                    <ActionButton disabled={!hasChanges}
                                  variant={"primary"}
                                  handleClick={done => handleSaveName(done)}/>
                </InputGroup>
            </Col>
            <Col>
                <HoverTooltip hidden={user!.resetPossible}
                              text="Only users with password can perform reset">
                    <ActionButton handleClick={done => handleResetPassword(done)} variant="primary"
                                  disabled={!user?.resetPossible}
                                  title="Reset password"
                                  icon={<EnvelopeIcon className="icon-style"/>}
                    />
                </HoverTooltip>
            </Col>
        </Row>
    )
}