import React from "react";
import {PencilSquareIcon} from "@heroicons/react/16/solid";

interface EditableCellProps {
    value: string;
    disable?: boolean;
}

export const EditableCell = (props: EditableCellProps) => {
    return (
        <div className="d-flex flex-row justify-content-between">
            <span>{props.value}</span>
            {!props.disable && <span className="text-muted"><PencilSquareIcon className="icon-style-sm"></PencilSquareIcon></span>}
        </div>
    );
}