import {FullContainer} from "../../components/containers/FullContainer";
import React, {useEffect, useMemo, useState} from "react";
import {getBillingUsage} from "../../api/billing";
import {useAlerts} from "../../hooks/useAlerts";
import {Title} from "../../components/Title";
import {BillingUsage} from "../../interfaces";
import {organizationUuidState} from "../../store";
import {useRoles} from "../../hooks/UsePermissions";
import {useAtom} from "jotai/react";
import {AgTable} from "../../components/AgTable";
import {Section} from "../../components/Section";

export const BillingUsageView = () => {

    const organizationUuid = useAtom(organizationUuidState)[0]
    const [billingUsage, setBillingUsage] = useState<BillingUsage | null>(null)
    const {addError} = useAlerts()
    const [isOwner] = useRoles()

    useEffect(() => {
        getBillingUsage()
            .then(data => setBillingUsage(data.usage))
            .catch(error => {
            })
    }, [addError, isOwner, organizationUuid])

    const rows = useMemo(() => [
        {
            resource: "Processes",
            plan: billingUsage?.allowedProcesses,
            used: billingUsage?.actualProcesses
        },
        {
            resource: "Active Projects",
            plan: billingUsage?.allowedProjects,
            used: billingUsage?.actualProjects
        },
        {
            resource: "Users",
            plan: billingUsage?.allowedUsers,
            used: billingUsage?.actualUsers
        },

    ], [billingUsage, billingUsage])


    return (
        <FullContainer>
            <Title title={"Billing Usage"} className={"mb-5"}/>
            <Section title="Current plan">
                <div style={{width: "600px", height: "176px"}}>
                    <AgTable
                        columnDefs={[
                            {
                                field: "resource",
                                width: 200,
                            },
                            {
                                field: "used",
                                width: 200,
                            },
                            {
                                field: "plan",
                                width: 200,
                            },
                        ]}
                        rowData={rows}
                        rowClass="row-hover"
                        suppressCellFocus={true}
                    />
                </div>
            </Section>
        </FullContainer>
);
}