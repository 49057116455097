import fetcher from "./fetcher";
import {OrganizationMember, PendingInvitation, Role, SetupOrganizationRequest} from "../interfaces";
import {organizationUuidState} from "../store";
import {store} from "../index";

export const leaveOrganization = (uuid: string): Promise<void> => {
    return fetcher('POST', `/organization/${uuid}/leave`, uuid);
}

export const deleteOrganization = (uuid: string): Promise<void> => {
    return fetcher('DELETE', `/organization/${uuid}`, uuid);
}

export const getOrganizationMembers = (): Promise<OrganizationMember[]> => {
    const organizationUuid = store.get(organizationUuidState)
    return fetcher('GET', `/organization/${organizationUuid}/members`, organizationUuid);
}

export const removeOrganizationMember = (userUuid: string): Promise<void> => {
    const organizationUuid = store.get(organizationUuidState)
    return fetcher('DELETE', `/organization/${organizationUuid}/member/${userUuid}`, organizationUuid);
}

export interface UpdateOrganizationMember {
    userUuid: string;
    role?: Role,
    teamUuid?: string;
}

export const updateOrganizationMember = (request: UpdateOrganizationMember): Promise<void> => {
    const organizationUuid = store.get(organizationUuidState)
    return fetcher('POST', `/organization/${organizationUuid}/member/${request.userUuid}`, organizationUuid, {
        role: request.role,
        teamUuid: request.teamUuid,
    });
}

export const getOrganizationInvitations = (): Promise<PendingInvitation[]> => {
    const organizationUuid = store.get(organizationUuidState)
    return fetcher('GET', `/organization/${organizationUuid}/invitations`, organizationUuid);
}

export const setupOrganization = (request: SetupOrganizationRequest): Promise<{
    uuid: string
}> => {
    return fetcher('POST', `/organization/setup`, null, request);
}
