import React from "react";
import {UserOrganizationList} from "./UserOrganizationList";
import {EditUserProfile} from "./EditUserProfile";
import {Title} from "../../components/Title";
import {userState} from "../../store";
import {UserInvitations} from "../../components/UserInvitations";
import {FullContainer} from "../../components/containers/FullContainer";
import {useAtom} from "jotai/react";

export function UserProfileView() {
    const user = useAtom(userState)[0];

    return <FullContainer>
        <Title title="Profile" subtitle={user?.email} className="mb-5"/>
        <EditUserProfile></EditUserProfile>
        <UserOrganizationList></UserOrganizationList>
        <UserInvitations title="My invitations"></UserInvitations>
    </FullContainer>
}

