import {Dropdown, Nav} from "react-bootstrap";
import PlusIcon from "@heroicons/react/24/solid/PlusIcon";
import React from "react";
import {organizationState, organizationUuidState, userState} from "../../store";
import {useNavigate} from "react-router";
import {ArrowsRightLeftIcon} from "@heroicons/react/24/solid";
import {useAtom, useSetAtom} from "jotai/react";

interface SidebarOrganizationSelectProps {
    collapsed: boolean;
}

export const SidebarOrganizationSelect = ({collapsed}: SidebarOrganizationSelectProps) => {
    const user = useAtom(userState)[0]
    const navigate = useNavigate();
    const setOrganizationUuid = useSetAtom(organizationUuidState);
    const organization = useAtom(organizationState)[0];

    const style = {
        padding: '8px 0 8px 16px',
    }

    return (
        <Dropdown style={style}>
            <Dropdown.Toggle as={Nav.Link} className="d-flex align-items-center link-secondary">
                <ArrowsRightLeftIcon className="link-info" style={{width: '24px'}}/>
                <span className={`link-info fade-in-text text-truncate ${collapsed ? '' : 'visible'}`}
                      style={{maxWidth: "130px"}}>{organization?.name}</span>
            </Dropdown.Toggle>
            <Dropdown.Menu className=".sidebar-dropdown">
                {user?.organizations?.map(org => (
                    <Dropdown.Item key={org.uuid}
                                   onClick={() => setOrganizationUuid(org.uuid)}>{org.name}
                    </Dropdown.Item>
                ))}
                <Dropdown.Item onClick={() => navigate("/setup-organization")}
                               className="d-flex align-items-center">
                    <PlusIcon className="me-1 icon-style"/> Add new</Dropdown.Item>
            </Dropdown.Menu>
        </Dropdown>
    )
}