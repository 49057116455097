import React from "react"

interface FullContainerProps {
    children: any
    className?: string,
    width?: 50 | 75 | 100
}

export function FullContainer(props: FullContainerProps) {
    return (<>
        <div style={{maxWidth: '1600px', marginLeft: 'auto', marginRight: 'auto'}}
             className={`d-flex flex-column justify-content-center mt-5 mb-5 ps-5 pe-5 w-${props.width||100}`}>
            {props.children}
        </div>
    </>)
}