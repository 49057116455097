import React from "react";
import {DismissibleAlert} from "./DismissibleAlert";
import {useAlerts} from "../../hooks/useAlerts";

export const DismissibleAlertList = () => {
    const {alerts, removeAlert} = useAlerts()
    return (<>
        {
            alerts.length > 0 &&
            <div style={{maxWidth: '1600px', marginLeft: 'auto', marginRight: 'auto'}}
                 className={`d-flex flex-column mt-5 mb-5 ps-5 pe-5`}>
                {alerts.map(alert => (
                    <DismissibleAlert key={alert.id}
                                      variant={alert.variant}
                                      onClose={() => removeAlert(alert.id)}>
                        {alert.messageHtml ?
                            <div onClick={() => removeAlert(alert.id)}>{alert.messageHtml}</div>
                            :
                            <span>{alert.message}</span>}

                    </DismissibleAlert>
                ))}
            </div>
        }
    </>);
}