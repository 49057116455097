import {UserOrganization} from "../../interfaces";
import XMarkIcon from "@heroicons/react/24/solid/XMarkIcon";
import SignOutIcon from "@heroicons/react/24/solid/ArrowRightEndOnRectangleIcon";
import React from "react";
import {ROLES} from "../../utils/Constants";
import {organizationUuidState} from "../../store";
import {useSetAtom} from "jotai/react";

interface OrganizationListCardProps {
    organization: UserOrganization,
    handleLeave: (organization: UserOrganization) => void,
    handleDelete: (organization: UserOrganization) => void,
    active: boolean
}

export const UserOrganizationRow = (props: OrganizationListCardProps) => {
    const setOrganizationUuid = useSetAtom(organizationUuidState)

    return (
        <tr>
            <td className="align-middle pointer"
                onClick={() => setOrganizationUuid(props.organization.uuid)}
            >{props.organization.name} {props.active && (<span className="text-muted">(active)</span>)}</td>
            <td className="align-middle">{props.organization.roles.map(role => ROLES[role])}</td>
            <td>
                {props.organization.roles.includes('OWNER') &&
                    <XMarkIcon onClick={() => props.handleDelete(props.organization)}
                               className="icon-style link-danger pointer"/>}
                {props.organization.roles.includes('MEMBER') &&
                    <SignOutIcon
                        onClick={() => props.handleLeave(props.organization)}
                        className="icon-style link-danger pointer"/>}
            </td>
        </tr>)
}