import React from "react";
import {FullContainer} from "../../components/containers/FullContainer";
import {Title} from "../../components/Title";

export const TeamReportView = () => {
    return (
        <FullContainer>
            <Title title="Team Report" className="mb-5"></Title>
        </FullContainer>
    );
}