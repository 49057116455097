import {Table} from "react-bootstrap";
import {MemberRow} from "./MemberRow";
import React, {useEffect, useState} from "react";
import {OrganizationMember, Team} from "../../../interfaces";
import {Section} from "../../../components/Section";
import {userState} from "../../../store";
import {useAlerts} from "../../../hooks/useAlerts";
import {getTeams} from "../../../api/teams";
import {getOrganizationMembers} from "../../../api/organizations";
import {useAtom} from "jotai/react";

interface ActiveUsersParams {
    organizationUuid?: string,
    isOwner: boolean,
    members: OrganizationMember[],
    setMembers: (value: any) => void
}

export const ActiveUsersList = ({isOwner, members, setMembers}: ActiveUsersParams) => {

    const user = useAtom(userState)[0];
    const {addError} = useAlerts()
    const [teams, setTeams] = useState<Team[]>([]);

    const reload = () => {
        getOrganizationMembers()
            .then(data => setMembers(data))
            .catch(error => addError(error));
    }

    useEffect(() => {
        getTeams()
            .then(data => setTeams(data))
            .catch(addError);
    }, [addError]);


    return (<Section title="Active users">
        <Table className="fixed-table">
            <thead>
            <tr>
                <th>Name</th>
                <th>Email</th>
                <th>Team</th>
                <th>Role</th>
                <th>Actions</th>
            </tr>
            </thead>
            <tbody>
            {members.map(member => <MemberRow
                teams={teams}
                key={member.uuid}
                member={member}
                isCurrentUser={user?.email === member.email}
                isCurrentUserOwner={isOwner}
                reload={reload}
            />)}
            </tbody>
        </Table>
    </Section>)
}