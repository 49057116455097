import React, {Suspense, useEffect, useMemo} from 'react';
import {Outlet} from "react-router";
import {Sidebar} from './components/sidebar/Sidebar';
import {TopNavBar} from "./components/TopNavBar";
import {DismissibleAlertList} from "./components/alerts/AlertsList";
import {LoadingSpinner} from "./routes/Loading";
import {userState} from "./store";
import "react-datepicker/dist/react-datepicker.css";
import {useAtom} from "jotai/react";
import {ToastMessage} from "./components/toasts/ToastMessage";

function App() {

    const user = useAtom(userState)[0]
    const visible = useMemo(() => !!user?.organizations?.length, [user])

    useEffect(() => {
        // Lazy load the library
        const loadAvoidLib = async () => {
            const module = (await import(/* webpackPreload: true */ './routes/process-view/graph/LibAvoidRouter'));
            const AvoidRouter = module.AvoidRouter;
            await AvoidRouter.load();
            console.log('avoidlib-js loaded');
        };
        loadAvoidLib();
    }, []);

    return (<div style={{display: "flex", flexDirection: "column", height: "100vh"}}>
            <TopNavBar/>
            <div className="app-container" style={{display: "flex", flexGrow: 1}}>
                <Sidebar visible={visible}/>
                <div className="content" style={{width: "100%"}}>
                    <DismissibleAlertList/>
                    <ToastMessage/>
                    <Suspense fallback={<LoadingSpinner/>}>
                        <Outlet/>
                    </Suspense>
                </div>
            </div>
        </div>
    )
}

export default App;
