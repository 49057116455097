import {PendingInvitation} from "../../../interfaces";
import XMarkIcon from "@heroicons/react/24/solid/XMarkIcon";
import React from "react";
import {INVITATION_STATUSES, ROLES} from "../../../utils/Constants";
import {useAlerts} from "../../../hooks/useAlerts";
import {ClipboardIcon} from "@heroicons/react/24/solid";
import {useToast} from "../../../components/toasts/UseToast";

interface PendingInvitationRowProps {
    invitation: PendingInvitation,
    canRevoke: boolean,
    handleCancel: (invitation: PendingInvitation) => void,
    handleDelete: (invitation: PendingInvitation) => void,
}

export const PendingInvitationRow = ({
                                         invitation,
                                         canRevoke,
                                         handleCancel,
                                         handleDelete,
                                     }: PendingInvitationRowProps) => {
    const {addError} = useAlerts();
    const [setToast] = useToast();

    const handleCopy = (invitation: PendingInvitation) => {
        navigator.clipboard
            .writeText(invitation.link)
            .catch(addError)
    };

    return (<tr>
            <td className="align-middle">
                {invitation?.email}
            </td>
            <td className="align-middle" style={{paddingLeft: "10px"}}>
                {invitation.team.name}
            </td>
            <td className="align-middle" style={{paddingLeft: "10px"}}>
                {ROLES[invitation!.role]}
            </td>
            <td className="align-middle">
                {INVITATION_STATUSES[invitation.status]}
            </td>
            <td className="align-middle link-danger">
                <div className="d-flex"
                     style={{cursor: "pointer"}}>
                    {canRevoke && invitation.status === 'PENDING' &&
                        <XMarkIcon className="icon-style" onClick={() => handleCancel(invitation)}/>
                    }
                    {canRevoke && invitation.status !== 'PENDING' &&
                        <XMarkIcon className="icon-style" onClick={() => handleDelete(invitation)}/>
                    }
                    {invitation.status === 'PENDING' &&
                        <ClipboardIcon className="ms-2 icon-style link-info" onClick={() => {
                            handleCopy(invitation);
                            setToast("Link coped to clipboard")
                        }}/>
                    }
                </div>
            </td>
        </tr>
    )
}