import _ from "lodash";
import {HandoverData, HandoverItem, PageData, Project, ProjectInsight, TaskInfo} from "../interfaces";
import {organizationUuidState} from "../store";
import fetcher from "./fetcher";
import {store} from "../index";

export interface CreateProjectRequest {
    projectName: string;
    startDate: string;
    ownerUuid: string;
    dailyValue: number;
    description: string;
    processUuid: string;
}

export const saveProject = (request: CreateProjectRequest): Promise<{ uuid: string }> => {
    const organizationUuid = store.get(organizationUuidState)
    return fetcher('POST', `/project`, organizationUuid, request)
}

export interface UpdateProjectRequest {
    projectUuid: string;
    projectName?: string;
    startDate?: string;
    dailyValue?: number;
    description?: string;
    status?: 'CANCELLED' | 'ON_HOLD' | 'NOT_STARTED';
    statusChangeComment?: string | null;
}

export const updateProject = (request: UpdateProjectRequest): Promise<{ uuid: string }> => {
    const organizationUuid = store.get(organizationUuidState)

    return fetcher('POST', `/project/${request.projectUuid}`, organizationUuid, {
        projectName: request.projectName,
        startDate: request.startDate,
        dailyValue: request.dailyValue,
        description: request.description,
        status: request.status,
        statusChangeComment: request.statusChangeComment
    })

}

export const getProject = (uuid: string): Promise<{ project: Project }> => {
    const organizationUuid = store.get(organizationUuidState)
    return fetcher('GET', `/project/${uuid}`, organizationUuid)
}

export interface ProjectSearchRequest {
    name?: string | null;
    code?: string | null;
    status?: string | null;
    owner?: string | null;
    page: number;
}

export const searchProjects = (request: ProjectSearchRequest): Promise<PageData<Project>> => {
    const organizationUuid = store.get(organizationUuidState)
    return fetcher('POST', `/project/search`, organizationUuid, {
        name: request.name,
        code: request.code,
        status: request.status,
        owner: request.owner,
        page: request.page || 0,
    })

}

export interface UpdateProjectTaskRequest {
    taskUuid: string;
    finishedDate?: string | null;
    description?: string;
    handoverData?: HandoverData;
    skip?: boolean;
}

export const updateProjectTask = (request: UpdateProjectTaskRequest): Promise<{ uuid: string }> => {
    const organizationUuid = store.get(organizationUuidState)
    const cleanedRequest = _.pickBy({
        finishedDate: request.finishedDate,
        description: request.description,
        handoverData: request.handoverData,
        skip: request.skip
    }, value => value !== undefined);
    return fetcher('POST', `/project/task/${request.taskUuid}`, organizationUuid, cleanedRequest)

}

export interface UpdateProjectSubtaskRequest {
    subtaskUuid: string;
    finishedDate?: string | null;
    skip?: boolean;
}

export const updateProjectSubtask = (request: UpdateProjectSubtaskRequest): Promise<{ uuid: string }> => {
    const organizationUuid = store.get(organizationUuidState)
    const cleanedRequest = _.pickBy({
        finishedDate: request.finishedDate,
        skip: request.skip
    }, value => value !== undefined);
    return fetcher('POST', `/project/subtask/${request.subtaskUuid}`, organizationUuid, cleanedRequest)
}

export const getHandoverItem = (uuid: string, target: string): Promise<{ handoverItem: HandoverItem }> => {
    const organizationUuid = store.get(organizationUuidState)
    return fetcher('GET', `/project/task/${uuid}/handover/${target}`, organizationUuid)
}

export const getHandoverList = (uuid: string): Promise<{ handoverData: HandoverData }> => {
    const organizationUuid = store.get(organizationUuidState)
    return fetcher('GET', `/project/task/${uuid}/handover`, organizationUuid)
}

export const getActiveTeamTasks = (size: number, page: number): Promise<PageData<TaskInfo>> => {
    const organizationUuid = store.get(organizationUuidState)
    return fetcher('GET', `/project/task/active?size=${size}&page=${page}`, organizationUuid)
}

export const getUpcomingTeamTasks = (size: number, page: number): Promise<PageData<TaskInfo>> => {
    const organizationUuid = store.get(organizationUuidState)
    return fetcher('GET', `/project/task/upcoming?size=${size}&page=${page}`, organizationUuid)
}

export interface ProjectInsights {
    uuid: string;
    from: string;
}

export const getProjectInsights = (request: ProjectInsights): Promise<{items: ProjectInsight[]}> => {
    const organizationUuid = store.get(organizationUuidState)
    return fetcher('POST', `/project/insight`, organizationUuid, {
        uuid: request.uuid,
        from: request.from
    })
}