import React from 'react';
import ReactDOM from 'react-dom/client';
import './custom.scss';
import {RouterProvider,} from "react-router-dom";
import {router} from "./router";
import {ModalProvider} from "react-modal-hook";
import {ErrorBoundary, Provider as RollbarProvider} from "@rollbar/react";
import {createStore} from "jotai/vanilla";
import {Provider as JotaiProvider} from 'jotai';

const rollbarConfig = {
    accessToken: process.env.REACT_APP_ROLLBAR_ID,
    environment: process.env.REACT_APP_ROLLBAR_ENV,
};
const rollbarEnabled = process.env.REACT_APP_ROLLBAR_ENABLED === 'true'
const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
const store = createStore();

let tree = <JotaiProvider store={store}>
    <ErrorBoundary>
        <ModalProvider>
            <RouterProvider router={router}/>
        </ModalProvider>
    </ErrorBoundary>
</JotaiProvider>
if (rollbarEnabled) {
    tree = <RollbarProvider config={rollbarConfig}>{tree}</RollbarProvider>
}
root.render(tree);

export {store}
