import {Row, Table} from "react-bootstrap";
import {UserOrganizationRow} from "./UserOrganizationRow";
import React from "react";
import {UserOrganization} from "../../interfaces";
import {deleteOrganization, leaveOrganization} from "../../api/organizations";

import {organizationUuidState, userState} from "../../store";
import {useNavigate} from "react-router";
import {useAlerts} from "../../hooks/useAlerts";
import {getUser} from "../../api/users";
import {useConfirmModal} from "../../components/modals/UseConfirmModal";
import {useAtom} from "jotai/react";


export const UserOrganizationList = () => {
    const [user, setUser] = useAtom(userState);
    const [organizationUuid, setOrganizationUuid] = useAtom(organizationUuidState);
    const navigate = useNavigate();
    const {addError} = useAlerts();
    const {showModal} = useConfirmModal()

    const handleLeave = (organization: UserOrganization) => {
        showModal(`Are you sure you want to leave ${organization.name}?`, () => leaveOrganization(organization.uuid)
            .catch(error => addError(error))
            .then(_ => handlePostRemove()));
    }

    const handleDelete = (organization: UserOrganization) => {
        showModal(`Are you sure you want to delete ${organization.name}?`, () => deleteOrganization(organization.uuid)
            .catch(error => addError(error))
            .then(_ => handlePostRemove()));
    }

    const handlePostRemove = async () => {
        return getUser()
            .then(data => {
                setUser(data)
                if (data.organizations.length === 0) {
                    throw Error("No organizations left");
                }
                const requiresOrgChange = !data.organizations?.find(org => org.uuid === organizationUuid)
                if (requiresOrgChange) {
                    setOrganizationUuid(data.organizations[0].uuid)
                }
            }).catch(error => {
                setUser(null);
                navigate("/landing")
                addError(error)
            })
    }


    return (
        <Row className="mt-5">
            <h4>My organizations</h4>
            <div className="mt-2">
                <Table className="fixed-table">
                    <thead>
                    <tr>
                        <th>Name</th>
                        <th>Role</th>
                        <th className="align-content-end">Actions</th>
                    </tr>
                    </thead>
                    <tbody>
                    {user?.organizations?.map(organization => (
                        <UserOrganizationRow handleLeave={handleLeave}
                                             active={organization.uuid === organizationUuid}
                                             handleDelete={handleDelete}
                                             key={organization.uuid}
                                             organization={organization}/>
                    ))}
                    <tr>
                        <td colSpan={3}
                            onClick={() => navigate('/setup-organization')}
                            className="text-center text-muted pointer">Click to set up new organization
                        </td>
                    </tr>
                    </tbody>
                </Table>
            </div>
        </Row>
    )
}