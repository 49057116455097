import React, {useEffect} from "react";
import {DndContext, DragEndEvent, useSensor, useSensors,} from "@dnd-kit/core";
import {WizardProcess, WizardTask} from "../../../interfaces";
import {MouseSensor} from "./MouseSensor";
import {useNavigate, useParams} from "react-router";
import {organizationUuidState, wizardArrangeTasksState, wizardProcessState} from "../../../store";
import WizardFrame from "../WizardFrame";
import {getWizardProcess, updateWizardProcess} from "../../../api/wizard";
import {useAlerts} from "../../../hooks/useAlerts";
import {Card, CardBody, CardText, Col, Row} from "react-bootstrap";
import {Section} from "../../../components/Section";
import {arrayMove, SortableContext, verticalListSortingStrategy} from "@dnd-kit/sortable";
import {SortableElement} from "./SortableElement";
import {OrderedTaskRow} from "./OrderedTaskRow";
import {useAtom} from "jotai/react";
import {useResetAtom} from "jotai/react/utils";

export default function WizardArrange() {
    const navigate = useNavigate();
    const resetWizardProcess = useResetAtom(wizardProcessState);
    const [tasks, setTasks] = useAtom<WizardTask[]>(wizardArrangeTasksState)
    const [wizardProcess, setWizardProcess] = useAtom<WizardProcess>(wizardProcessState)
    const params = useParams();
    const {addError} = useAlerts();
    const organizationUuid = useAtom(organizationUuidState)[0]

    useEffect(() => {
        if (params.uuid) {
            getWizardProcess(params.uuid)
                .then(data => {
                    data.tasks = data.tasks.map(task => task.tool ? task : {
                        ...task,
                        tool: {name: 'None', selected: true}
                    })
                    setWizardProcess(data);
                })
                .catch(error => addError(error))
        }
    }, [addError, params.uuid, setWizardProcess, organizationUuid]);

    const handlePrev = () => {
        navigate(`/wizard/teams-and-tools/${wizardProcess.uuid}`)
    }

    const handleNext = () => {
        if (wizardProcess.uuid) {
            updateWizardProcess({...wizardProcess, status: 'COMPLETE'})
                .then(response => navigate(`/process/${response.processUuid}`))
                .then(_ => resetWizardProcess())
                .catch(error => addError(error))
        }
    }

    const handleDragEnd = ({active, over}: DragEndEvent) => {
        if (!over) {
            return;
        }
        if (active.id !== over.id) {
            const activeIndex = active.data.current?.sortable.index;
            const overIndex = over.data.current?.sortable.index || 0;

            setTasks(prev => arrayMove(prev, activeIndex, overIndex))
        }
    }

    const sensors = useSensors(
        useSensor(MouseSensor, {
            activationConstraint: {distance: 2},
        }),
    )

    return (
        <WizardFrame prev={handlePrev}
                     next={handleNext}
                     title="Let's learn more about your process"
                     nextDisabledTooltip={'Set a team and tool for each task'}
                     nextLabel={"Save and exit"}>
            <DndContext
                sensors={sensors}
                onDragEnd={handleDragEnd}
            >
                <Section title={"Arrange tasks"} className="mt-3">
                    <Row>
                        <Col className="mb-3">
                            <Card bg="secondary" border="secondary" className="ps-2 pe-2 pt-1 pb-1 h-100">
                                <SortableContext items={tasks.map(task => (task.name))}
                                                 strategy={verticalListSortingStrategy}>
                                    {tasks.map(task => (
                                        <SortableElement key={task.name} id={task.name}>
                                            <OrderedTaskRow task={task}></OrderedTaskRow>
                                        </SortableElement>
                                    ))}
                                </SortableContext>
                            </Card>
                        </Col>
                        <Col md={9} xl={3}>
                            <Card style={{border: "none"}} bg="info">
                                <CardBody>
                                    <CardText>
                                        Drag the tasks into correct order and assign Teams and Tools for each.
                                    </CardText>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Section>
            </DndContext>
        </WizardFrame>
    )
}











