import React from "react";
import {Title} from "../../components/Title";
import {userState} from "../../store";
import {UserInvitations} from "../../components/UserInvitations";
import {FullContainer} from "../../components/containers/FullContainer";
import {useNavigate} from "react-router";
import {useAtom} from "jotai/react";

export const PendingInvitationsView = () => {

    const user = useAtom(userState)[0];
    const navigate = useNavigate();

    return (<>
        <FullContainer>
            <Title title="Pending invitations" subtitle={`${user?.email}`} className="mb-5"/>
            <UserInvitations onNoMorePendingLeft={() => navigate('/home')}/>
        </FullContainer>
    </>)
}